import axios from "axios"
import { CardContent, CardHeader } from "@material-ui/core"
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons"
import MaterialTable from "material-table"
import React, { forwardRef, useState } from "react"

import { deleteAPI, getAPI, putAPI } from "../../../../helper/api-consume.js"
import Dashboard from "../../../../layouts/dashboard.jsx"
import FetchingLayer from "../../../../components/global/fetching-layer.jsx"
import { Link as RouterLink } from "react-router-dom"

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const TUP = () => {
  const [khs, setKhs] = useState({})
  const [uraianPekerjaan, setUraianPekerjaan] = useState([])
  const header = [
    {
      title: "Id",
      field: "id",
      editable: "never",
    },
    {
      title: "KHS",
      field: "khs_id",
      lookup: khs,
    },
    {
      title: "Nomor Item",
      field: "nomor_item",
    },
    {
      title: "Designator",
      field: "designator",
    },
    {
      title: "Uraian Pekerjaan",
      field: "uraian_pekerjaan",
    },
    {
      title: "Satuan",
      field: "satuan",
    },
    {
      title: "Harga Material",
      field: "harga_material",
      type: "numeric",
      render: (data) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(data["harga_material"]),
    },
    {
      title: "Harga Jasa",
      field: "harga_jasa",
      type: "numeric",
      render: (data) =>
        new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
        }).format(data["harga_jasa"]),
    },
  ]

  const fetchData = async () => {
    try {
      const response = await axios.all([
        getAPI(`/khss`),
        getAPI(`/uraian_pekerjaans`),
      ])
      const [khss, uraians] = response.map((x) => x.data)
      const tempKHS = [
        ...khss.khss.map((x) => ({
          [x.id]: `KHS ${x.nomor_kontrak} - Paket ${x.paket}`,
        })),
      ]
      setKhs({
        ...tempKHS.reduce((acc, cur) => {
          const key = Object.keys(cur)[0]
          acc[key] = cur[key]
          return acc
        }),
      })
      const tempURAIAN = [
        ...uraians.uraian_pekerjaans.map((uraian) => {
          let temp = {}
          for (var key in uraian) {
            if (key !== "khs") {
              temp[key] = uraian[key]
            } else {
              temp["khs_id"] = uraian[key].id
            }
          }
          return temp
        }),
      ]
      setUraianPekerjaan((uraian) => [...uraian, ...tempURAIAN])
    } catch (error) {
      console.error(error)
    }
  }

  const updateData = async (newData, oldData) => {
    try {
      const { data } = await putAPI(`/uraian_pekerjaans/${newData.id}`, newData)
      console.log(data)
      setUraianPekerjaan((uraian) => [
        ...uraian.map((x) => (x.id !== newData.id ? x : newData)),
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const deleteData = async (oldData) => {
    try {
      const { data } = await deleteAPI("/uraian_pekerjaans", oldData.id)
      setUraianPekerjaan((uraian) => [
        ...uraian.filter((x) => x.id !== oldData.id),
      ])
      console.log(data)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Dashboard>
      <CardHeader title={`Uraian Pekerjaan`} />
      <CardContent>
        <FetchingLayer fetchData={fetchData}>
          <MaterialTable
            icons={tableIcons}
            title="Uraian Pekerjaan"
            columns={header}
            data={uraianPekerjaan}
            options={{
              headerStyle: {
                textAlign: "center",
              },
              rowStyle: {
                textAlign: "center",
              },
              actionsColumnIndex: -1,
            }}
            editable={{
              onRowUpdate: (newData, oldData) => updateData(newData, oldData),
              onRowDelete: (oldData) => deleteData(oldData),
            }}
          />
        </FetchingLayer>
      </CardContent>
    </Dashboard>
  )
}

export default TUP
