import { CardContent, CardHeader } from '@material-ui/core';
import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Dashboard from '../../layouts/dashboard';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		flexWrap: 'nowrap',
		// Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
		transform: 'translateZ(0)',
	},
	title: {
		color: theme.palette.primary.light,
	},
	titleBar: {
		background:
			'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
	dialogContainer: {},
	bgCard: {
		background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
		width: '30%',
		borderRadius: 4,
		padding: '1rem',
		color: '#fff',
		marginBottom: 16,
		marginLeft: 15,
	},
	bgCard2: {
		background: 'linear-gradient(45deg, #FF3CAC 30%, #784BA0  90%)',
		width: '30%',
		borderRadius: 4,
		padding: '1rem',
		color: '#fff',
		marginBottom: 16,
		marginLeft: 15,
	},
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
	},
}));

const DashboardPage = (props) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [imgDialog, setImgDialog] = useState('');

	const handleClickOpen = (img) => {
		setImgDialog(img);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const tileData = [
		{
			img:
				'https://tripolapanata.com/images/project_20200625_1111006-0.png',
			title: 'Dokumentasi 1',
		},
		{
			img:
				'https://tripolapanata.com/images/photo_2020-06-25_09-47-17.jpg',
			title: 'Dokumentasi 2',
		},
		{
			img:
				'https://tripolapanata.com/images/project_20200625_1032112-0.png',
			title: 'Dokumentasi 2',
		},
		{
			img:
				'https://tripolapanata.com/images/kedua.jpg',
			title: 'Dokumentasi 2',
		},
	];
	return (
		<Dashboard>
			<div className={classes.wrapper}>
				<div className={classes.bgCard}>
					<Typography variant="h6" style={{ flexGrow: 1 }}>
						TRIPOLA SDM UNGGUL
					</Typography>
				</div>
				<div className={classes.bgCard2}>
					<Typography variant="h6" style={{ flexGrow: 1 }}>
						Selamat datang di sistem Tripola
					</Typography>
				</div>
			</div>

			<CardHeader title="Gallery" />
			<Grid item xs={12}>
				<CardContent>
					<div className={classes.root}>
						<GridList className={classes.gridList} cols={2.5}>
							{tileData.map((tile) => (
								<GridListTile key={tile.img}>
									<img
										src={tile.img}
										alt={tile.title}
										onClick={() => handleClickOpen(tile.img)}
									/>
								</GridListTile>
							))}
						</GridList>
					</div>
				</CardContent>
			</Grid>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth="xl"
			>
				<DialogTitle id="alert-dialog-title">{'Gallery'}</DialogTitle>
				<DialogContent>
					<img src={imgDialog} alt="foto gallery" width="100%" />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Dashboard>
	);
};

export default DashboardPage;
