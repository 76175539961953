import React, { Fragment } from 'react';
import {
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';

const CostProject = ({ costProjects }) => {
	return (
		<Fragment>
			<Typography
				gutterBottom
				variant="subtitle1"
				style={{ marginTop: '2rem' }}
			>
				Cost Project
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">ID</TableCell>
						<TableCell align="center">Project ID</TableCell>
						<TableCell align="center">Tanggal</TableCell>
						<TableCell align="center">Keperluan</TableCell>
						<TableCell align="center">Nilai Keperluan</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{costProjects?.map((costProject, key) => (
						<TableRow key={key}>
							<TableCell align="center">{costProject?.id}</TableCell>
							<TableCell align="center">
								{costProject?.monitoring?.project?.project_id}
							</TableCell>
							<TableCell align="center">{costProject?.tanggal}</TableCell>
							<TableCell align="center">{costProject?.keperluan}</TableCell>
							<TableCell align="center">
								{Intl.NumberFormat('id', {
									style: 'currency',
									currency: 'idr',
								}).format(costProject?.nilai_keperluan)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<hr
				style={{
					height: '10px',
					width: '100%',
					backgroundColor: 'blue',
					border: 0,
					margin: '1rem 0',
				}}
			/>
		</Fragment>
	);
};

export default CostProject;
