import axios from 'axios'
import {
  Button,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core'
import { faReply } from '@fortawesome/free-solid-svg-icons'
import React, { useState, Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import { getAPI } from '../../../../helper/api-consume.js'
import {
  CostProject,
  Monitoring,
  Overview,
  Progres,
  Termin,
} from './components'
import Dashboard from '../../../../layouts/dashboard.jsx'
import FetchingLayer from '../../../../components/global/fetching-layer.jsx'

const ReportProjectDetail = (props) => {
  const history = useHistory()
  const { projectId } = history.location.state
  const [projectOverview, setProjectOverview] = useState({})
  const [projectProgress, setProjectProgress] = useState({})
  const [projectMonitoring, setProjectMonitoring] = useState({})
  const [projectTermin, setProjectTermin] = useState([])
  const [costProject, setCostProject] = useState([])
  const [totInvoice, setTotInvoice] = useState(0)
  const [cashAndBank, setCashAndBank] = useState(0)
  const [totTermin, setTotTermin] = useState(0)
  const [totCostProject, setTotCostProject] = useState(0)
  const [keuntungan, setKeuntungan] = useState(0)

  const fetchData = async () => {
    try {
      const [first, second, third, fourth, fifth] = await axios.all([
        getAPI('/segment_project_overviews'),
        getAPI('/segment_progresss'),
        getAPI('/monitorings'),
        getAPI('/termins'),
        getAPI('/cost_projects'),
      ])

      const project_overview = first.data.segment_project_overviews.filter(
        (x) => x.project.id === projectId,
      )[0]
      const project_progress = second.data.segment_progresss.filter(
        (x) => x.adm_penagihan.project.id === projectId,
      )[0]
      const project_termin = fourth?.data?.termins?.filter(
        (x) => x.monitoring.project.id === projectId,
      )
      const cost_project = fifth?.data?.cost_projects?.filter(
        (x) => x.monitoring.project.id === projectId,
      )

      setProjectOverview(project_overview)
      setProjectProgress(project_progress)
      setProjectMonitoring(
        third.data.monitorings.filter((x) => x.project.id === projectId)[0],
      )
      setProjectTermin(project_termin)
      setCostProject(cost_project)

      const tot_invoice = project_overview?.total_nilai_rekon
      const tot_cash_and_bank = project_progress?.adm_penagihan?.cash_and_bank
      const tot_termin =
        project_termin?.reduce((acc, cur) => acc + cur?.nilai, 0) || 0
      const tot_cost_project =
        cost_project?.reduce((acc, cur) => acc + cur?.nilai_keperluan, 0) || 0
      setTotInvoice(tot_invoice)
      setCashAndBank(tot_cash_and_bank)
      setTotTermin(tot_termin)
      setTotCostProject(tot_cost_project)
      setKeuntungan(tot_cash_and_bank - tot_termin - tot_cost_project)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Fragment>
      <CardContent>
        <FetchingLayer fetchData={fetchData}>
          <Grid container>
            <Grid item xs={6}>
              <Typography gutterBottom variant="h5" component="h2">
                Laporan Project {projectOverview.project?.nama}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                align="right"
              >
                {Intl.DateTimeFormat('id', {
                  weekday: 'long',
                  day: '2-digit',
                  month: 'long',
                  year: 'numeric',
                }).format(Date.now())}
              </Typography>
            </Grid>
          </Grid>
          <Overview overview={projectOverview} />
          <Progres progres={projectProgress} />
          <Monitoring monitoring={projectMonitoring} />
          <Termin termins={projectTermin} />
          <CostProject costProjects={costProject} />
          <div style={{ margin: '1.5rem 0' }}>
            <Typography gutterBottom variant="subtitle1">
              Summary
            </Typography>
            <table
              style={{
                fontFamily: 'Arial',
                backgroundColor: '#0093E9',
                fontStyle: 'bold',
                color: '#fff',
                padding: '10px',
              }}
            >
              <tr>
                <td>Total Nilai Invoice</td>
                <td style={{ paddingLeft: '2rem' }}></td>
                <td>
                  {Intl.NumberFormat('id', {
                    style: 'currency',
                    currency: 'idr',
                  }).format(totInvoice)}
                </td>
              </tr>
              <tr>
                <td>Nilai Cash and Bank</td>
                <td style={{ paddingLeft: '2rem' }}></td>
                <td>
                  {Intl.NumberFormat('id', {
                    style: 'currency',
                    currency: 'idr',
                  }).format(cashAndBank)}
                </td>
              </tr>
              <tr>
                <td>Total Nilai Termin</td>
                <td style={{ paddingLeft: '2rem' }}></td>
                <td>
                  {Intl.NumberFormat('id', {
                    style: 'currency',
                    currency: 'idr',
                  }).format(totTermin)}
                </td>
              </tr>
              <tr>
                <td>Total Nilai Cost Project</td>
                <td style={{ paddingLeft: '2rem' }}></td>
                <td>
                  {Intl.NumberFormat('id', {
                    style: 'currency',
                    currency: 'idr',
                  }).format(totCostProject)}
                </td>
              </tr>
              <tr>
                <td>Keuntungan</td>
                <td style={{ paddingLeft: '2rem' }}></td>
                <td>
                  {Intl.NumberFormat('id', {
                    style: 'currency',
                    currency: 'idr',
                  }).format(keuntungan)}
                </td>
              </tr>
            </table>
          </div>
        </FetchingLayer>
      </CardContent>
    </Fragment>
  )
}

export default ReportProjectDetail
