import axios from 'axios';
import {
	Button,
	CardContent,
	CardHeader,
	TextField,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	Search,
	ViewColumn,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import {
	deleteAPI,
	getAPI,
	postAPI,
	putAPI,
} from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const useStyles = makeStyles((theme) => ({
	bgCard: {
		background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
		width: '30%',
		borderRadius: 4,
		padding: '1rem',
		color: '#fff',
		marginBottom: 16,
	},
}));

const BOQ = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const { khsId, projectId } = history.location.state;
	const [boqs, setBoqs] = useState([]);
	const [completion, setCompletion] = useState([]);
	const [uraianPekerjaans, setUraianPekerjaans] = useState({});
	const [up, setUp] = useState([]);
	const [totals, setTotals] = useState([]);
	const columns = [
		{
			title: 'Designator',
			field: 'uraian_pekerjaan_id',
			lookup: uraianPekerjaans,
			editComponent: ({ onChange, value }) => (
				<Autocomplete
					id="combo-box-demo"
					options={completion}
					getOptionLabel={(option) => option.title}
					value={value}
					onChange={(event, newValue) => onChange(newValue.id)}
					renderInput={(params) => <TextField {...params} />}
				/>
			),
		},
		{
			title: 'Uraian Pekerjaan',
			field: 'uraian_pekerjaan.material.nama',
			editable: 'never',
		},
		{
			title: 'Satuan',
			field: 'uraian_pekerjaan.material.satuan',
			editable: 'never',
		},
		{
			title: 'Harga Material',
			field: 'harga_material',
			emptyValue: '0',
			editable: 'onUpdate',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['harga_material']),
		},
		{
			title: 'Harga Jasa',
			field: 'harga_jasa',
			emptyValue: '0',
			editable: 'onUpdate',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['harga_jasa']),
		},
		{
			title: 'Volume',
			field: 'volume',
			type: 'numeric',
		},
	];

	const fetchData = async () => {
		try {
			const responses = await axios.all([
				getAPI('/boqs'),
				getAPI('/uraian_pekerjaans'),
			]);
			const [first, second] = responses.map((x) => x.data);

			if (first.boqs !== null) {
				const firstTemp = first.boqs.map((item) => {
					const total_harga_material = item.harga_material * item.volume;
					const total_harga_jasa = item.harga_jasa * item.volume;
					const total_harga = total_harga_jasa + total_harga_material;
					return {
						...item,
						uraian_pekerjaan_id: item.uraian_pekerjaan.id,
						total_harga,
						total_harga_jasa,
						total_harga_material,
					};
				});
				const boqTemp = firstTemp.filter((x) => x.project.id === projectId);
				const {
					total_harga_jasa,
					total_harga_material,
					total_harga,
				} = boqTemp.reduce(
					(acc, cur) => {
						acc['total_harga_jasa'] += cur.total_harga_jasa;
						acc['total_harga_material'] += cur.total_harga_material;
						acc['total_harga'] += cur.total_harga;
						return acc;
					},
					{
						total_harga_jasa: 0,
						total_harga_material: 0,
						total_harga: 0,
					}
				);
				setTotals([
					{
						'Total Harga Material': total_harga_material,
					},
					{
						'Total Harga Jasa': total_harga_jasa,
					},
					{
						'Total Harga': total_harga,
					},
				]);
				setBoqs([...boqTemp]);
			}

			if (second.uraian_pekerjaans !== null) {
				const temp = second.uraian_pekerjaans.filter((x) => x.khs.id === khsId);
				const secondTemp = temp.reduce((acc, cur) => {
					const title = `${cur.material.designator} - ${cur.nomor_item}`;
					acc[cur.id] = title;
					return acc;
				}, {});
				setCompletion([
					...temp.map((x) => ({
						id: x.id,
						title: `${x.material.designator} - ${x.nomor_item}`,
					})),
				]);
				setUp([...temp]);
				setUraianPekerjaans({ ...secondTemp });
			}
		} catch (error) {
			console.error(error);
		}
	};

	const addItem = async (newData) => {
		const { harga_jasa, harga_material } = up.filter(
			(item) => item.id === newData.uraian_pekerjaan_id
		)[0];
		const body = {
			...newData,
			harga_material,
			harga_jasa,
			project_id: projectId,
		};
		try {
			await postAPI('/boqs', body);
			await fetchData();
		} catch (error) {
			alert('Error saat tambah boq');
			console.error(error);
		}
	};

	const updateItem = async (newData, oldData) => {
		const body = {
			...newData,
			project_id: projectId,
		};
		try {
			await putAPI(`/boqs/${oldData.id}`, body);
			await fetchData();
		} catch (error) {
			alert('Error saat edit boq');
			console.error(error);
		}
	};

	const removeItem = async (rowData) => {
		try {
			await deleteAPI('/boqs', rowData.id);
			await fetchData();
		} catch (error) {
			alert('Error saat menghapus boq');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title={
					<div className={classes.bgCard}>
						{totals.map((item, id) => {
							const val = new Intl.NumberFormat('id-ID', {
								style: 'currency',
								currency: 'IDR',
							}).format(Object.values(item)[0]);
							const key = Object.keys(item)[0];
							return (
								<Typography key={id} variant="h6" style={{ flexGrow: 1 }}>
									{`${key}: ${val}`}
								</Typography>
							);
						})}
					</div>
				}
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faReply} />}
						onClick={() => history.goBack()}
					>
						Kembali
					</Button>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="BOQ DRM"
						columns={columns}
						data={boqs}
						editable={{
							onRowAdd: (newData) => addItem(newData),
							onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
							onRowDelete: (oldData) => removeItem(oldData),
						}}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default BOQ;
