import {
  AppBar,
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Drawer,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core'
import {
  faAngleRight,
  faSignOutAlt,
  faTachometerAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom'

import { AuthContext } from '../App.js'
import { claim, logout } from '../helper/auth.js'
import Menu from './menu.jsx'
import { filterByRole } from '../helper/roles.js'

const drawerWidth = 250

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}))

const Dashboard = ({ children }) => {
  const history = useHistory()
  const classes = useStyles()

  const [auth, dispatch] = useContext(AuthContext)
  const [menus, setMenus] = useState(auth.routes)
  const [loading, setLoading] = useState(false)
  const [userName, setUserName] = useState('')
  const [userRole, setUserRole] = useState('')
  const rawPath = history.location.pathname.split('/').filter(Boolean)
  const [paths] = useState([
    ...rawPath.map((x, id) =>
      id === 0 ? x : rawPath.slice(0, id + 1).join('/'),
    ),
  ])

  const fetchData = async () => {
    setLoading(true)
    try {
      const { data } = await claim()
      console.log(data)
      const role = data.jwt.role_name.toLowerCase()
      const name = data.jwt.user_name
      const routes = [...filterByRole({ role })]
      const payload = { role, routes, name }
      setUserRole(role)
      setUserName(name)
      setMenus(routes)
      dispatch({ type: 'CLAIM', payload })
      setLoading(false)
    } catch (error) {
      if (error?.response?.status === 401) {
        Cookies.remove('auth')
        history.push('/auth/login')
      }
    }
  }

  const handleLogout = async () => {
    try {
      await logout()
      Cookies.remove('auth')
      history.replace('/auth/login')
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (auth.role === '' && userName === '') {
      fetchData()
    }
  }, [])

  return (
    <div className={classes.root}>
      {loading ? (
        <LinearProgress color="secondary" />
      ) : (
        <Fragment>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
                SENA
              </Typography>
              <Button
                color="inherit"
                onClick={handleLogout}
                endIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
              >
                Logout
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>
              <Divider />
              <List>
                <ListItem button>
                  <ListItemAvatar>
                    <Avatar>
                      <FontAwesomeIcon icon={faUser} />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={userName !== '' ? userName : auth.name}
                    secondary={userRole !== '' ? userRole : auth.role}
                  />
                </ListItem>
                <ListItem button component={Link} to="/dashboard">
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faTachometerAlt} size="lg" />
                  </ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
                {menus.map((menu, idx) => (
                  <Menu key={idx} menu={menu} />
                ))}
              </List>
            </div>
          </Drawer>
          <main className={classes.content}>
            <Toolbar />
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<FontAwesomeIcon icon={faAngleRight} />}
              style={{ margin: '1.5rem 0' }}
            >
              {paths.map((path, idx) =>
                idx < paths.length - 1 ? (
                  <Link
                    key={idx}
                    color="inherit"
                    to={`/${path}`}
                    style={{ textDecoration: 'none' }}
                  >
                    {path.split('/')[idx]}
                  </Link>
                ) : (
                  <Typography key={idx} color="textPrimary">
                    {path.split('/')[idx]}
                  </Typography>
                ),
              )}
            </Breadcrumbs>
            <Card>{children}</Card>
          </main>
        </Fragment>
      )}
    </div>
  )
}

export default Dashboard
