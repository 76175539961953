import axios from "axios"

const baseurl = process.env.REACT_APP_API_BASEURL

const getAPI = async (link = "/") => {
  try {
    const response = await axios({
      method: "get",
      url: `${baseurl + link}`,
    })
    return response
  } catch (error) {
    throw error
  }
}

const postAPI = async (link = "/", body = {}) => {
  const formData = new FormData()
  for (var key in body) {
    formData.append(key, body[key])
  }

  try {
    const response = await axios({
      method: "post",
      url: `${baseurl + link}`,
      data: formData,
    })
    return response
  } catch (error) {
    throw error
  }
}

const putAPI = async (link = "/", body = {}) => {
  const formData = new FormData()
  for (var key in body) {
    formData.append(key, body[key])
  }

  try {
    const response = await axios({
      method: "put",
      url: `${baseurl + link}`,
      data: formData,
    })
    return response
  } catch (error) {
    throw error
  }
}

const deleteAPI = async (link = "/", id = -1) => {
  try {
    const response = await axios({
      method: "DELETE",
      url: `${baseurl + link}/${id}`,
    })
    return response
  } catch (error) {
    console.log(error)
    throw error
  }
}

export { getAPI, postAPI, putAPI, deleteAPI }

