import { Grid } from "@material-ui/core"
import React from "react"

const AuthLayout = ({ children }) => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item xs={10} md={5}>
        {children}
      </Grid>
    </Grid>
  )
}

export default AuthLayout
