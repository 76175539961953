import React, { Fragment } from 'react';
import {
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';

const Overview = ({ overview }) => {
	return (
		<Fragment>
			<Typography gutterBottom variant="subtitle1">
				Project Overview
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">ID Project</TableCell>
						<TableCell align="center">Nama Project</TableCell>
						<TableCell align="center">Nilai DRM Material</TableCell>
						<TableCell align="center">Nilai DRM Jasa</TableCell>
						<TableCell align="center">Nilai DRM Total</TableCell>
						<TableCell align="center">Nilai Rekon Material</TableCell>
						<TableCell align="center">Nilai Rekon Jasa</TableCell>
						<TableCell align="center">Nilai Rekon Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell align="center">{overview.project?.project_id}</TableCell>
						<TableCell align="center">{overview.project?.nama}</TableCell>
						<TableCell align="center">
							{Intl.NumberFormat('id', {
								style: 'currency',
								currency: 'idr',
							}).format(overview?.rekon?.boq?.harga_material)}
						</TableCell>
						<TableCell align="center">
							{Intl.NumberFormat('id', {
								style: 'currency',
								currency: 'idr',
							}).format(
								overview?.rekon?.boq?.harga_jasa
							)}
						</TableCell>
						<TableCell align="center">
							{Intl.NumberFormat('id-ID', {
								style: 'currency',
								currency: 'idr',
							}).format(overview?.total_nilai_drm)}
						</TableCell>
						<TableCell align="center">
							{Intl.NumberFormat('id-ID', {
								style: 'currency',
								currency: 'idr',
							}).format(overview?.rekon_harga_material)}
						</TableCell>
						<TableCell align="center">
							{Intl.NumberFormat('id-ID', {
								style: 'currency',
								currency: 'idr',
							}).format(overview?.rekon_harga_jasa)}
						</TableCell>
						<TableCell align="center">
							{Intl.NumberFormat('id-ID', {
								style: 'currency',
								currency: 'idr',
							}).format(overview?.total_nilai_rekon)}
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>
			<hr
				style={{
					height: '10px',
					width: '100%',
					backgroundColor: 'blue',
					border: 0,
					margin: '1rem 0',
				}}
			/>
		</Fragment>
	);
};

export default Overview;
