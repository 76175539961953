import React, { forwardRef, useState } from 'react';
import Dashboard from '../../../../layouts/dashboard';
import { Button, CardContent, CardHeader, Card } from '@material-ui/core';
import FetchingLayer from "../../../../components/global/fetching-layer.jsx"

// material component
import MaterialTable from 'material-table';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

//icon import material ui
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { faPlus } from "@fortawesome/free-solid-svg-icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  deleteAPI,
  getAPI,
} from "../../../../helper/api-consume.js"


import { Link as RouterLink, useHistory } from "react-router-dom"



const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const UserIndex = (props) => {
  const history = useHistory()
  const [users, setUsers] = useState([]);
  const [dataId, setDataId] = useState(0);

  const [state, setState] = React.useState({
    columns: [
      { title: 'ID', field: 'id', editable: "never", },
      { title: 'Nama', field: 'nama' },
      { title: 'Email', field: 'email' },
      { title: 'Role', field: 'role' },
    ]
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (data) => {
    setOpen(true);
    setDataId(data);
    console.log(dataId);
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    try {
      const { data } = await getAPI(`/users`)
      console.log(data);
      setUsers([
        ...data.users.map((x, i) => ({
          id: x.id,
          no: i + 1,
          nama: x.name,
          email: x.email,
          role: x.role.name,
          role_id: x.role.id,
        })),
      ])
    } catch (e) {
      console.error(e);

    }
  }

  const deleteData = async (oldData) => {
    try {
        console.log(oldData);
        const { data } = await deleteAPI(`/users`,oldData)
        fetchData();
        console.log(data)
        handleClose()
    } catch (e) {
        console.error(e)
    }
}




  return (
    <Dashboard >
      <Card>
        <CardHeader
          title="User"
          action={
            <Button
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faPlus} size="sm" />}
              component={RouterLink}
              to="/dashboard/data-master/user/create"
            >
              tambah
                    </Button>
          }

        />
        <CardContent>
          <FetchingLayer fetchData={fetchData}>
            <MaterialTable
              icons={tableIcons}
              title="Tabel User"
              columns={state.columns}
              data={users}
              options={{
                headerStyle: {
                  textAlign: "center",
                },
                rowStyle: {
                  textAlign: "center",
                },
                actionsColumnIndex: -1,
              }}
              actions={[
                {
                  icon: tableIcons.Edit,
                  tooltip: "Edit User",
                  onClick: (event, rowData) =>
                    history.push(`/dashboard/data-master/user/${rowData.id}`),
                },
                {
                  icon: tableIcons.Delete,
                  tooltip: "Delete User",
                  onClick: (event, rowData) =>
                  handleClickOpen(rowData.id),
                },
              ]}

            />
          </FetchingLayer>

          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">{"Yakin menghapus user ini ?"}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Batal
          </Button>
              <Button onClick={()=>deleteData(dataId)} color="primary">
                Ya, Hapus !
          </Button>
            </DialogActions>
          </Dialog>

        </CardContent>

      </Card>

    </Dashboard>
  );
}

export default UserIndex;