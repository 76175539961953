import {
  Avatar,
  CardContent,
  CardHeader,
  FormGroup,
  Grid,
  TextField,
} from "@material-ui/core"
import React from "react"

import Dashboard from "../../../layouts/dashboard"

const Profile = () => {
  return (
    <Dashboard>
      <CardHeader title="Profile" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <form>
              <FormGroup>
                <TextField
                  margin="normal"
                  name="username"
                  defaultValue="Foo Bar Baz"
                  type="text"
                  label="Username"
                  variant="outlined"
                  size="small"
                />
              </FormGroup>
              <FormGroup>
                <TextField
                  margin="normal"
                  name="email"
                  defaultValue="foo@bar.com"
                  type="email"
                  label="Email"
                  variant="outlined"
                  size="small"
                />
              </FormGroup>
            </form>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Avatar src="" />
          </Grid>
        </Grid>
      </CardContent>
    </Dashboard>
  )
}

export default Profile
