import axios from 'axios'
import {
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { faPaperPlane, faReply } from '@fortawesome/free-solid-svg-icons'
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router-dom'

import { getAPI, putAPI } from '../../../../helper/api-consume.js'
import Dashboard from '../../../../layouts/dashboard.jsx'
import FetchingLayer from '../../../../components/global/fetching-layer.jsx'
import { useHistory, Link as RouterLink } from 'react-router-dom'

const KHSEdit = () => {
  const history = useHistory()
  const { nomorKontrak } = useParams()
  const [khs, setKhs] = useState({})
  const [customers, setCustomers] = useState([])
  const regionals = [...Array.from(Array(7), (x, i) => `${i + 1}`), 'Nasional']
  const pakets = [...Array.from(Array(12), (x, i) => i + 1)]
  const [attachFile, setAttachFile] = useState(false)

  const fetchData = async () => {
    try {
      const responses = await axios.all([
        getAPI('/customers'),
        getAPI(`/khss/${nomorKontrak}`),
      ])
      const [first, second] = responses.map((x) => x.data)
      setCustomers([...first.customers])
      setKhs({ ...second.khss[0], customer_id: second.khss[0].customer.id })
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setAttachFile(!attachFile)
    try {
      const response = await putAPI(`/khss/${khs['id']}`, khs)
      setAttachFile(!attachFile)
      if (!attachFile) {
        history.goBack()
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  const handleChange = ({ target }) => {
    const value =
      target.type === 'checkbox'
        ? target.checked
        : target.type === 'file'
        ? target.files[0]
        : target.value
    setKhs({ ...khs, [target.name]: value })
  }

  return (
    <Dashboard>
      <CardHeader
        title="Edit KHS"
        action={
          <Button
            variant="contained"
            color="primary"
            endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
            component={RouterLink}
            to="/dashboard/data-master/khs"
          >
            kembali
          </Button>
        }
      />
      <CardContent>
        <FetchingLayer fetchData={fetchData}>
          <Grid container justify="center">
            <Grid item xs={8}>
              <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column' }}
                encType="multipart/form-data"
              >
                <FormControl margin="normal">
                  <InputLabel>Customer</InputLabel>
                  <Select
                    value={khs.customer !== undefined ? khs.customer_id : ''}
                    name="customer_id"
                    onChange={handleChange}
                  >
                    {customers.map((customer, id) => (
                      <MenuItem key={id} value={customer.id}>
                        {customer.nama}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  type="text"
                  label="Nomor Kontrak"
                  name="nomor_kontrak"
                  value={khs['nomor_kontrak']}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  type="date"
                  label="Tanggal Kontrak"
                  name="tanggal_kontrak"
                  value={khs['tanggal_kontrak']}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  type="date"
                  label="Tanggal Berakhir"
                  name="tanggal_berakhir"
                  value={khs['tanggal_berakhir']}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                />
                <FormControl margin="normal">
                  <InputLabel>Regional</InputLabel>
                  <Select
                    name="regional"
                    value={khs.regional !== undefined ? khs.regional : -1}
                    onChange={handleChange}
                  >
                    {regionals.map((regional, id) => (
                      <MenuItem key={id} value={regional}>
                        {regional}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl margin="normal">
                  <InputLabel>Paket</InputLabel>
                  <Select
                    name="paket"
                    value={khs.paket !== undefined ? khs.paket : -1}
                    onChange={handleChange}
                  >
                    {pakets.map((paket, id) => (
                      <MenuItem key={id} value={paket}>
                        Paket - {paket}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  type="file"
                  label="File KHS"
                  name="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
                <FormControl fullWidth margin="normal">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                  >
                    {attachFile ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      'submit'
                    )}
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </FetchingLayer>
      </CardContent>
    </Dashboard>
  )
}

export default KHSEdit
