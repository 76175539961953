import axios from 'axios'
import {
  Button,
  CardContent,
  CardHeader,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { faReply } from '@fortawesome/free-solid-svg-icons'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Visibility,
} from '@material-ui/icons'
import MaterialTable from 'material-table'
import React, { forwardRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import { deleteAPI, getAPI, putAPI } from '../../../../helper/api-consume.js'
import Dashboard from '../../../../layouts/dashboard.jsx'
import FetchingLayer from '../../../../components/global/fetching-layer.jsx'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles((theme) => ({
  bgCard: {
    background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
    width: '30%',
    borderRadius: 4,
    padding: '1rem',
    color: '#fff',
    marginBottom: 16,
  },
}))

const Rekon = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const { projectId } = history.location.state
  const [rekons, setRekons] = useState([])
  const [boqs, setBoqs] = useState({})
  const [totHargaRekon, setTotHargaRekon] = useState([])
  const columns = [
    {
      title: 'Nomor BOQ',
      field: 'boq_id',
      lookup: boqs,
    },
    {
      title: 'Designator',
      field: 'boq.uraian_pekerjaan.material.designator',
      editable: 'never',
    },
    {
      title: 'Uraian Pekerjaan',
      field: 'boq.uraian_pekerjaan.material.nama',
      editable: 'never',
    },
    {
      title: 'Satuan',
      field: 'boq.uraian_pekerjaan.material.satuan',
      editable: 'never',
    },
    {
      title: 'Harga Material DRM',
      field: 'boq.harga_material',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data.boq.harga_material),
    },
    {
      title: 'Harga Jasa DRM',
      field: 'boq.harga_jasa',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data.boq.harga_jasa),
    },
    {
      title: 'Total Harga Material DRM',
      field: 'total_harga_material',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data['total_harga_material']),
    },
    {
      title: 'Total Harga Jasa DRM',
      field: 'total_harga_jasa',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data['total_harga_jasa']),
    },
    {
      title: 'Volume DRM',
      field: 'boq.volume',
      editable: 'never',
      render: (data) =>
        data?.boq?.volume > 0 ? (
          data?.boq?.volume
        ) : (
          <p style={{ backgroundColor: 'red', padding: '.5rem' }}>0</p>
        ),
    },
    {
      title: 'Harga Material Rekon',
      field: 'harga_material_rekon',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data.harga_material_rekon),
    },
    {
      title: 'Harga Jasa Rekon',
      field: 'harga_jasa_rekon',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data.harga_jasa_rekon),
    },
    {
      title: 'Volume Rekon',
      field: 'volume',
    },
  ]

  const fetchData = async () => {
    try {
      const responses = await axios.all([getAPI('/rekons'), getAPI('/boqs')])
      const [first, second] = responses.map((x) => x.data)
      const secondTemp = second.boqs.reduce((acc, cur) => {
        acc[cur.id] = cur.uraian_pekerjaan.nomor_item
        return acc
      }, {})
      const firstTemp = first.rekons.map((item, idx) => {
        const { boq } = item
        const total_harga_material = boq.harga_material * boq.volume
        const total_harga_jasa = boq.harga_jasa * boq.volume
        const harga_jasa_rekon = boq.harga_jasa * item.volume
        const harga_material_rekon = boq.harga_material * item.volume
        return {
          ...item,
          boq_id: boq.id,
          total_harga_material,
          total_harga_jasa,
          harga_jasa_rekon,
          harga_material_rekon,
        }
      })
      const arrRekons = firstTemp.filter((x) => x.boq.project.id === projectId)
      const tempTotHargaRekon = arrRekons.reduce((acc, cur) => {
        const tiga = cur.harga_jasa_rekon + cur.harga_material_rekon
        acc += tiga
        return acc
      }, 0)
      setTotHargaRekon([
        {
          'Total Harga Material': arrRekons.reduce(
            (acc, cur) => acc + cur.harga_material_rekon,
            0,
          ),
        },
        {
          'Total Harga Jasa': arrRekons.reduce(
            (acc, cur) => acc + cur.harga_jasa_rekon,
            0,
          ),
        },
        {
          'Total Harga': tempTotHargaRekon,
        },
      ])
      setBoqs({ ...secondTemp })
      setRekons([...arrRekons])
    } catch (error) {
      console.error(error)
    }
  }

  const updateItem = async (newData, oldData) => {
    try {
      await putAPI(`/rekons/${oldData.id}`, newData)
      await fetchData()
    } catch (error) {
      alert('Error saat update data')
      console.error(error)
    }
  }

  return (
    <Dashboard>
      <CardHeader
        title={
          <div className={classes.bgCard}>
            {totHargaRekon.map((item, id) => {
              const harga = Intl.NumberFormat('id', {
                style: 'currency',
                currency: 'IDR',
              }).format(Object.values(item)[0])
              const key = Object.keys(item)[0]
              return (
                <Typography key={id} variant="h6" style={{ flexGrow: 1 }}>
                  {`${key}: ${harga}`}
                </Typography>
              )
            })}
          </div>
        }
        action={
          <Button
            variant="contained"
            color="primary"
            endIcon={<FontAwesomeIcon icon={faReply} />}
            onClick={() => history.goBack()}
          >
            Kembali
          </Button>
        }
      />
      <CardContent>
        <FetchingLayer fetchData={fetchData}>
          <MaterialTable
            icons={tableIcons}
            title="BOQ Rekon"
            columns={columns}
            data={rekons}
            editable={{
              onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
            }}
            options={{
              headerStyle: {
                textAlign: 'center',
              },
              rowStyle: {
                textAlign: 'center',
              },
              actionsColumnIndex: -1,
            }}
          />
        </FetchingLayer>
      </CardContent>
    </Dashboard>
  )
}

export default Rekon
