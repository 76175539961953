import {
	Button,
	CardContent,
	TextField,
	Typography,
	makeStyles,
	Card,
} from '@material-ui/core';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	Search,
	ViewColumn,
	SaveAlt,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import {
	deleteAPI,
	getAPI,
	postAPI,
	putAPI,
} from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
};
const useStyles = makeStyles((theme) => ({
	bgCard: {
		background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
		width: '30%',
		borderRadius: 4,
		padding: '1rem',
		color: '#fff',
		marginBottom: 16,
	},
	headerCOntainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));

const Termin = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const [termins, setTermins] = useState([]);
	const [monitorings, setMonitorings] = useState([]);
	const { monitoringId } = history.location.state;
	const titleTermin = `Tabel Termin ${termins[0]?.monitoring?.project?.nama}`;
	const terminKe = {
		1: 'Termin ke-1',
		2: 'Termin ke-2',
		3: 'Termin ke-3',
		4: 'Termin ke-4',
		5: 'Termin ke-5',
		6: 'Termin ke-6',
		akhir: 'Pelunasan',
	};

	const totalNilaiTermin = termins.reduce((acc, cur) => acc + cur.nilai, 0);

	const formatTotal = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
	}).format(totalNilaiTermin);

	console.log(formatTotal);

	const title = `Total Nilai Termin : ${formatTotal}`;

	console.log(totalNilaiTermin);

	const columns = [
		{
			title: 'ID',
			field: 'id',
			editable: 'never',
		},
		{
			title: 'Monitoring ID',
			field: 'monitoring.id',
			editable: 'never',
		},
		{
			title: 'Termin',
			field: 'termin',
			lookup: terminKe,
		},
		{
			title: 'Progress Pekerjaan',
			field: 'progress_pekerjaan',
		},
		{
			title: 'Persentase (%)',
			field: 'persentase_view',
			emptyValue: '0',
			render: (rowData) => rowData.persentase_view + ' %',
		},
		{
			title: 'Tanggal',
			field: 'tanggal',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Nilai',
			field: 'nilai',
			editable: 'onUpdate',
			emptyValue: '0',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['nilai']),
		},
	];

	const fetchData = async () => {
		try {
			const responses = await getAPI('/termins');
			const firstTemp = responses.data.termins.map((item) => {
				const persentase = item.persentase * 100;
				return {
					...item,
					persentase_view: persentase,
				};
			});

			const terminsTemp = firstTemp.filter(
				(x) => x.monitoring.id === monitoringId
			);
			setTermins([...terminsTemp]);
		} catch (error) {
			console.error(error);
		}

		try {
			const { data } = await getAPI(`/monitorings/${monitoringId}`);
			setMonitorings(data.monitorings);
		} catch (error) {
			console.error(error);
		}
	};

	console.log(termins);

	const addItem = async (newData) => {
		let persentaseConvert = parseFloat(newData.persentase_view) / 100;
		let nilai = 0;

		if (newData.termin == 'akhir') {
			nilai = monitorings[0].harga_kesepakatan_akhir_mitra - totalNilaiTermin;

			persentaseConvert = 0;
		} else {
			nilai = monitorings[0].harga_kesepakatan_awal_mitra * persentaseConvert;
		}

		const body = {
			termin: newData.termin,
			progress_pekerjaan: newData.progress_pekerjaan,
			persentase: persentaseConvert,
			monitoring_id: monitoringId,
			tanggal: newData.tanggal,
			nilai: nilai,
		};
		console.log(body);

		try {
			await postAPI('/termins', body);
			fetchData();
		} catch (e) {
			alert('Error saat tambah termin');
			console.error(e);
		}
	};

	const updateItem = async (newData, oldData) => {
		const persentaseConvert = parseFloat(newData.persentase_view) / 100;
		const body = {
			termin: newData.termin,
			progress_pekerjaan: newData.progress_pekerjaan,
			persentase: persentaseConvert,
			monitoring_id: monitoringId,
			tanggal: newData.tanggal,
			nilai: newData.nilai,
		};
		console.log(body);
		try {
			await putAPI(`/termins/${oldData.id}`, body);
			await fetchData();
		} catch (e) {
			alert('Error saat edit monitoring');
			console.error(e);
		}
	};

	const removeItem = async (rowData) => {
		try {
			await deleteAPI('/termins', rowData.id);
			await fetchData();
		} catch (error) {
			alert('Error saat menghapus termin');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<Card>
				<CardContent>
					<div className={classes.headerCOntainer}>
						<div className={classes.bgCard}>
							<Typography variant="h6" style={{ flexGrow: 1 }}>
								{title}
							</Typography>
						</div>
						<Button
							className={classes.btn}
							variant="contained"
							color="primary"
							endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
							component={RouterLink}
							to="/dashboard/finance/monitoring"
						>
							kembali
						</Button>
					</div>
					<FetchingLayer fetchData={fetchData}>
						<MaterialTable
							icons={tableIcons}
							title={titleTermin}
							columns={columns}
							data={termins}
							editable={{
								onRowAdd: (newData) => addItem(newData),
								onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
								onRowDelete: (oldData) => removeItem(oldData),
							}}
							options={{
								headerStyle: {
									textAlign: 'center',
								},
								actionsColumnIndex: -1,
								exportButton: true,
							}}
						/>
					</FetchingLayer>
				</CardContent>
			</Card>
		</Dashboard>
	);
};

export default Termin;
