import axios from 'axios';
import {
	CardContent,
	CardHeader,
	TextField,
	MenuItem,
	Select,
} from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	BorderOuter,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
	deleteAPI,
	getAPI,
	postAPI,
	putAPI,
} from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	BorderOuter: forwardRef((props, ref) => <BorderOuter {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MaterialRequisition = (props) => {
	const history = useHistory();
	const [materialRequisitions, setMaterialRequisitions] = useState([]);
	const [projects, setProjects] = useState({});
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const arr = new Array(5).fill(0);
	const columns = [
		{
			title: 'Nama Project',
			field: 'project_id',
			lookup: projects,
			editComponent: ({ onChange, value }) =>
				Object.keys(projects).length > 0 ? (
					<Autocomplete
						id="combo-box-demo"
						options={[
							...Object.entries(projects).map((item) => ({
								id: item[0],
								title: item[1],
							})),
						]}
						getOptionLabel={(option) => option.title}
						onChange={(event, newValue) => onChange(newValue.id)}
						renderInput={(params) => <TextField {...params} />}
					/>
				) : (
					'data kosong'
				),
		},
		{
			title: 'Tanggal',
			field: 'tanggal',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Material Requisition ID',
			field: 'material_requisition_id',
			editable: 'never',
		},
	];

	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		const sekarang = new Date();
		const tahun = thn !== '' ? thn : sekarang.getFullYear();
		try {
			const responses = await axios.all([
				getAPI(`/material_requisitions?year=${tahun}`),
				getAPI(`/projects`),
			]);
			const [first, second] = responses.map((item) => item.data);

			if (first.material_requisitions !== null) {
				const firstTemp = first.material_requisitions.map((item) => ({
					...item,
					project_id: item.project.id,
				}));
				setMaterialRequisitions([...firstTemp]);
			}

			if (second.projects !== null) {
				const secondTemp = second.projects.reduce((acc, cur) => {
					acc[cur.id] = `${cur.nama} | ${cur.project_id}`;
					return acc;
				}, {});
				setProjects({ ...secondTemp });
			}

			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	const addItem = async (newData) => {
		try {
			const { data } = await postAPI(`/material_requisitions`, newData);
			console.log({ ...data });
			await fetchData();
		} catch (error) {
			alert('Error saat tambah data');
			console.error(error);
		}
	};

	const updateItem = async (newData, oldData) => {
		try {
			await putAPI(`/material_requisitions/${oldData.id}`, newData);
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	const removeItem = async (rowData) => {
		try {
			await deleteAPI('/material_requisitions', rowData.id);
			await fetchData();
		} catch (error) {
			alert('Error saat menghapus data');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Material Requisition"
				action={
					<Select defaultValue={selectedYear} onChange={handleChange}>
						{arr?.map((x, id) => {
							const sekarang = new Date();
							const year = sekarang.getFullYear() - (arr.length - (id + 1));
							return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Material Requisition"
						columns={columns}
						data={materialRequisitions}
						editable={{
							onRowAdd: (newData) => addItem(newData),
							onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
							onRowDelete: (oldData) => removeItem(oldData),
						}}
						actions={[
							{
								icon: tableIcons.Add,
								tooltip: 'Detail Material Requisition',
								onClick: (event, rowData) =>
									history.push(
										`/dashboard/material-management/detail-requisition`,
										{
											requisitionId: rowData.id,
										}
									),
							},
							{
								icon: tableIcons.BorderOuter,
								tooltip: 'Material Fullfillment',
								onClick: (event, rowData) =>
									history.push(`/dashboard/material-management/fulfillment`, {
										requisitionId: rowData.id,
									}),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default MaterialRequisition;
