import React, { createContext, useReducer } from 'react'
import { BrowserRouter } from 'react-router-dom'

import Routes from './routes.js'

const initialState = {
  isAuthenticate: false,
  expires: 0,
  maxAges: 0,
  role: '',
  name: '',
  routes: [],
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticate: true,
        expires: action.payload.expires,
        maxAges: action.payload.maxAges,
        role: '',
      }
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticate: false,
        expires: 0,
        maxAges: 0,
        role: '',
      }
    case 'CLAIM':
      return {
        ...state,
        role: action.payload.role,
        name: action.payload.name,
        routes: action.payload.routes,
      }
    default:
      return state
  }
}

export const AuthContext = createContext()

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <AuthContext.Provider value={[state, dispatch]}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default App
