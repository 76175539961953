import axios from 'axios';
import {
	Button,
	CardContent,
	CardHeader,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { faPaperPlane, faReply } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import { useFormInput } from '../../../../helper/state.js';
import { useHistory, Link as RouterLink, useParams } from 'react-router-dom';

const EditPermintaan = () => {
	const history = useHistory();
	const permintaan = useFormInput({});
	const [loading, setLoading] = useState(false);
	const [permintaans, setPermintaans] = useState({});
	const [userId, setUserId] = useState();
	const { idpermintaan } = useParams();
	const weeks = [...Array.from(Array(70), (x, i) => i + 1)];

	const fetchData = async () => {
		try {
			const { data } = await getAPI('/claim');
			console.log(data);
			setUserId(data.jwt.user_id);
		} catch (error) {
			console.error(error);
		}

		try {
			const { data } = await getAPI(`/permintaans/${idpermintaan}`);
			console.log(permintaans);
			setPermintaans({ ...data.permintaans[0] });
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const formData = new FormData();

			console.log(permintaan.value);

			for (var key in permintaan.value) {
				formData.append(key, permintaan.value[key]);
			}
			formData.set('user_id', userId);
			console.log(formData);
			await axios({
				method: 'put',
				url: `${process.env.REACT_APP_API_BASEURL}/permintaans/${idpermintaan}`,
				data: formData,
			});
			history.goBack();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setLoading(true);
		fetchData();
		setLoading(false);
	}, []);

	return (
		<Dashboard>
			<CardHeader
				title={`Edit Permintaan minggu ke ${permintaans.ke}`}
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
						component={RouterLink}
						to="/dashboard/finance/permintaan"
					>
						kembali
					</Button>
				}
			/>
			<CardContent>
				{loading ? (
					'loading'
				) : (
					<Grid container justify="center">
						<Grid item xs={8}>
							<form
								onSubmit={handleSubmit}
								style={{ display: 'flex', flexDirection: 'column' }}
								encType="multipart/form-data"
							>
								<TextField
									fullWidth
									margin="normal"
									type="date"
									label="Tanggal Permintaan"
									name="tanggal"
									InputLabelProps={{ shrink: true }}
									onChange={permintaan.handleChange}
								/>
								<FormControl margin="normal">
									<InputLabel>Minggu ke-</InputLabel>
									<Select
										// value={permintaanData.ke !== undefined ? permintaanData.ke : -1}
										name="ke"
										onChange={permintaan.handleChange}
									>
										{weeks.map((week, id) => (
											<MenuItem key={id} value={week}>
												{week}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									fullWidth
									margin="normal"
									type="file"
									label="File Permintaan (format .pdf)"
									name="file"
									onChange={permintaan.handleChange}
									InputLabelProps={{ shrink: true }}
								/>
								<FormControl>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
									>
										submit
									</Button>
								</FormControl>
							</form>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Dashboard>
	);
};

export default EditPermintaan;
