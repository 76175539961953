import axios from 'axios'
import {
  Button,
  CircularProgress,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { faPaperPlane, faReply } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { getAPI } from '../../../../helper/api-consume.js'
import Dashboard from '../../../../layouts/dashboard.jsx'
import { useFormInput } from '../../../../helper/state.js'
import { useHistory, Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const KHSCreate = () => {
  const history = useHistory()
  const classes = useStyles()
  const khs = useFormInput({})
  const [khsPackage, setKhsPackage] = useState([])
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(false)
  const [attachFile, setAttachFile] = useState(false)
  const regionals = [...Array.from(Array(8), (x, i) => i)]
  const pakets = [...Array.from(Array(12), (x, i) => i + 1)]

  const fetchData = async () => {
    try {
      const { data } = await getAPI('/customers')
      console.log(data)
      setCustomers([...data.customers])
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setAttachFile(!attachFile)

    const requests = khsPackage.map((paket, id) => {
      const formData = new FormData()
      formData.append('paket', paket)
      for (var key in khs.value) {
        formData.append(key, khs.value[key])
      }
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_BASEURL}/khss`,
        data: formData,
      })
    })

    axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          console.log(responses)
          setAttachFile(!attachFile)
        }),
      )
      .then(() => {
        if (!attachFile) {
          history.goBack()
        }
      })
      .catch(console.error)
  }

  useEffect(() => {
    setLoading(true)
    fetchData()
    setLoading(false)
  }, [])

  return (
    <Dashboard>
      <CardHeader
        title="Tambah KHS"
        action={
          <Button
            variant="contained"
            color="primary"
            endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
            component={RouterLink}
            to="/dashboard/data-master/khs"
          >
            kembali
          </Button>
        }
      />
      <CardContent>
        {loading ? (
          'loading'
        ) : (
          <Grid container justify="center">
            <Grid item xs={8}>
              <form
                onSubmit={handleSubmit}
                style={{ display: 'flex', flexDirection: 'column' }}
                encType="multipart/form-data"
              >
                <FormControl margin="normal">
                  <InputLabel>Customer</InputLabel>
                  <Select name="customer_id" onChange={khs.handleChange}>
                    {customers.map((customer, id) => (
                      <MenuItem key={id} value={customer.id}>
                        {customer.nama}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  type="text"
                  label="Nomor Kontrak"
                  name="nomor_kontrak"
                  onChange={khs.handleChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  type="date"
                  label="Tanggal Kontrak"
                  name="tanggal_kontrak"
                  onChange={khs.handleChange}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  type="date"
                  label="Tanggal Berakhir"
                  name="tanggal_berakhir"
                  onChange={khs.handleChange}
                  InputLabelProps={{ shrink: true }}
                />
                <FormControl margin="normal">
                  <InputLabel>Regional</InputLabel>
                  <Select name="regional" onChange={khs.handleChange}>
                    {regionals.map((regional, id) => (
                      <MenuItem key={id} value={regional}>
                        {regional === 0 ? 'Nasional' : regional}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl margin="normal">
                  <InputLabel>Paket</InputLabel>
                  <Select
                    name="paket"
                    multiple
                    value={khsPackage}
                    input={<Input />}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={`Paket ${value}`}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                    onChange={(e) => setKhsPackage(e.target.value)}
                  >
                    {pakets.map((paket, id) => (
                      <MenuItem key={id} value={paket}>
                        <Checkbox checked={khsPackage.indexOf(paket) > -1} />
                        <ListItemText primary={`Paket ${paket}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  margin="normal"
                  type="file"
                  label="File KHS (.PDF)"
                  name="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={khs.handleChange}
                />
                <FormControl>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                  >
                    {attachFile ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      'submit'
                    )}
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Dashboard>
  )
}

export default KHSCreate
