import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AdminPenagihan } from './pages/dashboard/project-system/admin-penagihan';
import { BarangKeluar } from './pages/dashboard/material-management/barang-keluar';
import { BarangMasuk } from './pages/dashboard/material-management/barang-masuk';
import { BOQ } from './pages/dashboard/project-system/boq';
import { CostIndex, CostItem } from './pages/dashboard/finance/cost-project';
import YearlyReport from './pages/dashboard/report/year/home';
import Customer from './pages/dashboard/data-master/customer/home.jsx';
import Gudang from './pages/dashboard/data-master/gudang/home.jsx';
import Material from './pages/dashboard/data-master/material/home.jsx';
import Mitra from './pages/dashboard/data-master/mitra/home.jsx';
import Supplier from './pages/dashboard/data-master/supplier/home.jsx';
import Operasional from './pages/dashboard/finance/operasional/home.jsx';
import {
	ReportProject,
	ReportProjectDetail,
} from './pages/dashboard/report/project/index.js';
import DashboardPage from './pages/dashboard/index.jsx';
import Profile from './pages/dashboard/profile/index.jsx';
import { KHS, KHSCreate, KHSEdit } from './pages/dashboard/data-master/khs';
import { ListProject } from './pages/dashboard/project-system/list-project';
import Login from './pages/auth/login.jsx';
import { MaterialDetailRequisition } from './pages/dashboard/material-management/material-detail-requisition';
import { MaterialFullfillment } from './pages/dashboard/material-management/material-fullfillment';
import { MaterialRequisition } from './pages/dashboard/material-management/material-requisition';
import { Monitoring } from './pages/dashboard/finance/monitoring';
import { PengembalianBarang } from './pages/dashboard/material-management/pengembalian-barang';
import {
	CreatePermintaan,
	EditPermintaan,
	PermintaanIndex,
} from './pages/dashboard/finance/permintaan';
import PrivateRoute from './components/auth/private-route.js';
import {
	Project,
	ProjectCreate,
	ProjectDetail,
	ProjectEdit,
} from './pages/dashboard/project-system/project';
import { Rekon } from './pages/dashboard/project-system/rekon';
import { StatusProject } from './pages/dashboard/project-system/status-project';
import { Stok } from './pages/dashboard/material-management/stok';
import { Termin } from './pages/dashboard/finance/termin';
import { TUPCreate } from './pages/dashboard/data-master/uraian-pekerjaan';
import {
	CreateUser,
	EditUser,
	UserIndex,
} from './pages/dashboard/data-master/user';

const Routes = (props) => {
	return (
		<Switch>
			<Route exact path="/">
				<Redirect to="/auth/login" />
			</Route>
			<Route path="/auth/login" exact>
				<Login />
			</Route>
			<PrivateRoute exact component={DashboardPage} path="/dashboard" />
			<PrivateRoute exact component={Profile} path="/dashboard/profile" />
			<PrivateRoute
				exact
				component={PermintaanIndex}
				path="/dashboard/finance/permintaan"
			/>
			<PrivateRoute
				component={CreatePermintaan}
				path="/dashboard/finance/permintaan/create"
			/>
			<PrivateRoute
				exact
				component={EditPermintaan}
				path="/dashboard/finance/permintaan/:idpermintaan"
			/>
			<PrivateRoute
				component={Operasional}
				path="/dashboard/finance/permintaan/:idpermintaan/operasional"
			/>

			<PrivateRoute
				exact
				component={CostIndex}
				path="/dashboard/finance/cost"
			/>
			<PrivateRoute
				component={CostItem}
				path="/dashboard/finance/cost/:idcost/item"
			/>
			<PrivateRoute
				exact
				component={Monitoring}
				path="/dashboard/finance/monitoring"
			/>
			<PrivateRoute exact component={Termin} path="/dashboard/finance/termin" />

			{/* END FINANCE */}

			<PrivateRoute
				exact
				component={UserIndex}
				path="/dashboard/data-master/user"
			/>
			<PrivateRoute exact component={KHS} path="/dashboard/data-master/khs" />
			<PrivateRoute
				component={CreateUser}
				path="/dashboard/data-master/user/create"
			/>
			<PrivateRoute
				exact
				component={EditUser}
				path="/dashboard/data-master/user/:idUser"
			/>
			<PrivateRoute
				component={KHSCreate}
				path="/dashboard/data-master/khs/create"
			/>
			<PrivateRoute
				exact
				component={KHSEdit}
				path="/dashboard/data-master/khs/:nomorKontrak"
			/>
			<PrivateRoute
				component={TUPCreate}
				path="/dashboard/data-master/khs/:nomorKontrak/uraian-pekerjaan"
			/>
			<PrivateRoute
				component={Customer}
				path="/dashboard/data-master/customer"
			/>
			<PrivateRoute component={Gudang} path="/dashboard/data-master/gudang" />
			<PrivateRoute
				exact
				component={Project}
				path="/dashboard/project-system/project"
			/>
			<PrivateRoute
				component={ProjectCreate}
				path="/dashboard/project-system/project/create"
			/>
			<PrivateRoute
				exact
				component={ProjectDetail}
				path="/dashboard/project-system/project/:id"
			/>
			<PrivateRoute
				component={ProjectEdit}
				path="/dashboard/project-system/project/:id/edit"
			/>
			<PrivateRoute
				exact
				component={BOQ}
				path="/dashboard/project-system/boq"
			/>
			<PrivateRoute
				exact
				component={Rekon}
				path="/dashboard/project-system/rekon"
			/>
			<PrivateRoute
				exact
				component={ListProject}
				path="/dashboard/project-system/list-project"
			/>
			<PrivateRoute
				exact
				component={StatusProject}
				path="/dashboard/project-system/status-project"
			/>
			<PrivateRoute
				exact
				component={AdminPenagihan}
				path="/dashboard/project-system/admin-penagihan"
			/>
			<PrivateRoute component={Mitra} path="/dashboard/data-master/mitra" />
			<PrivateRoute
				component={Supplier}
				path="/dashboard/data-master/supplier"
			/>
			<PrivateRoute
				component={Material}
				path="/dashboard/data-master/material"
			/>
			<PrivateRoute
				exact
				component={MaterialRequisition}
				path="/dashboard/material-management/requisition"
			/>
			<PrivateRoute
				exact
				component={MaterialDetailRequisition}
				path="/dashboard/material-management/detail-requisition"
			/>
			<PrivateRoute
				exact
				component={MaterialFullfillment}
				path="/dashboard/material-management/fulfillment"
			/>
			<PrivateRoute
				exact
				component={Stok}
				path="/dashboard/material-management/stok"
			/>
			<PrivateRoute
				exact
				component={BarangMasuk}
				path="/dashboard/material-management/barang-masuk"
			/>
			<PrivateRoute
				exact
				component={BarangKeluar}
				path="/dashboard/material-management/barang-keluar"
			/>
			<PrivateRoute
				exact
				component={PengembalianBarang}
				path="/dashboard/material-management/pengembalian-barang"
			/>
			<PrivateRoute
				exact
				component={ReportProject}
				path="/dashboard/report/project/"
			/>
			<PrivateRoute
				exact
				component={ReportProjectDetail}
				path="/dashboard/report/project/detail"
			/>
			<PrivateRoute
				exact
				component={YearlyReport}
				path="/dashboard/report/year/"
			/>
			<Route path="*">
				<h1>404</h1>
			</Route>
		</Switch>
	);
};

export default Routes;
