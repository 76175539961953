import axios from 'axios';
import {
	Button,
	CircularProgress,
	CardContent,
	CardHeader,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	makeStyles,
} from '@material-ui/core';
import { faPaperPlane, faReply } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import { useFormInput } from '../../../../helper/state.js';
import { useHistory, Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},
}));

const CreatePermintaan = () => {
	const history = useHistory();
	const permintaan = useFormInput({});
	const [loading, setLoading] = useState(false);
	const [attachFile, setAttachFile] = useState(false);
	const [userId, setUserId] = useState();
	const weeks = [...Array.from(Array(70), (x, i) => i + 1)];

	const fetchData = async () => {
		try {
			const { data } = await getAPI('/claim');
			console.log(data);
			setUserId(data.jwt.user_id);
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setAttachFile(!attachFile);
		try {
			const formData = new FormData();

			console.log(permintaan.value);

			for (var key in permintaan.value) {
				formData.append(key, permintaan.value[key]);
			}
			const idUser = toString(userId);
			formData.set('user_id', userId);
			console.log(formData);
			await axios({
				method: 'post',
				url: `${process.env.REACT_APP_API_BASEURL}/permintaans`,
				data: formData,
			});
			setAttachFile(!attachFile);
			history.goBack();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		setLoading(true);
		fetchData();
		setLoading(false);
	}, []);

	return (
		<Dashboard>
			<CardHeader
				title="Tambah Permintaan"
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
						component={RouterLink}
						to="/dashboard/finance/permintaan"
					>
						kembali
					</Button>
				}
			/>
			<CardContent>
				{loading ? (
					'loading'
				) : (
					<Grid container justify="center">
						<Grid item xs={8}>
							<form
								onSubmit={handleSubmit}
								style={{ display: 'flex', flexDirection: 'column' }}
								encType="multipart/form-data"
							>
								<TextField
									fullWidth
									margin="normal"
									type="date"
									label="Tanggal Permintaan"
									name="tanggal"
									InputLabelProps={{ shrink: true }}
									onChange={permintaan.handleChange}
								/>
								<FormControl margin="normal">
									<InputLabel>Minggu ke -</InputLabel>
									<Select name="ke" onChange={permintaan.handleChange}>
										{weeks.map((week, id) => (
											<MenuItem key={id} value={week}>
												{week}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									fullWidth
									margin="normal"
									type="file"
									label="File Permintaan (format .pdf)"
									name="file"
									onChange={permintaan.handleChange}
									InputLabelProps={{ shrink: true }}
								/>
								<FormControl fullWidth margin="normal">
									<Button
										type="submit"
										variant="contained"
										color="primary"
										endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
									>
										{attachFile ? (
											<CircularProgress color="secondary" />
										) : (
											'kirim'
										)}
									</Button>
								</FormControl>
							</form>
						</Grid>
					</Grid>
				)}
			</CardContent>
		</Dashboard>
	);
};

export default CreatePermintaan;
