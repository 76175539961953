import axios from 'axios';
import { CardContent, CardHeader, MenuItem, Select } from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Search,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';

import { getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const Stok = (props) => {
	const [stoks, setStoks] = useState([]);
	const [materials, setMaterials] = useState({});
	const [gudangs, setGudangs] = useState({});
	const [arr, setArr] = useState([]);
	const [gudangFilter, setGudangFilter] = useState(0);
	const [loading, setLoading] = useState(true);
	const handleChange = ({ target }) => {
		setGudangFilter(target.value);
	};

	const columns = [
		{
			title: 'Designator',
			field: 'material_id',
			lookup: materials,
		},
		{
			title: 'Uraian Pekerjaan',
			field: 'material.nama',
			editable: 'never',
		},
		{
			title: 'Satuan',
			field: 'material.satuan',
			editable: 'never',
		},
		{
			title: 'Volume',
			field: 'volume',
			editable: 'never',
		},
		{
			title: 'Gudang',
			field: 'gudang_id',
			lookup: gudangs,
		},
	];

	useEffect(() => {
		if (loading) {
			fetchData(gudangFilter);
		} else if (gudangFilter !== 0) {
			fetchData(gudangFilter);
		}
	}, [gudangFilter]);

	const fetchData = async (gudang = 0) => {
		const filter = gudang !== 0 ? gudang : 0;
		try {
			const responses = await axios.all([
				getAPI(`/stoks?gudang=${filter}`),
				getAPI('/materials'),
				getAPI('/gudangs'),
			]);

			const [first, second, third] = responses.map((item) => item.data);
			setArr(third.gudangs);
			const firstTemp = first.stoks.map((item) => {
				return {
					...item,
					material_id: item.material.id,
					gudang_id: item.gudang.id,
				};
			});
			const secondTemp = second.materials.reduce((acc, cur) => {
				acc[cur.id] = `${cur.designator}`;
				return acc;
			}, {});
			const thirdTemp = third.gudangs.reduce((acc, cur) => {
				acc[cur.id] = `${cur.nama}`;
				return acc;
			}, {});
			delete thirdTemp[0];
			setGudangs({ ...thirdTemp });
			setStoks([...firstTemp]);
			setMaterials({ ...secondTemp });
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	console.log(gudangFilter);

	return (
		<Dashboard>
			<CardHeader
				title="Stok"
				action={
					<Select defaultValue={gudangFilter} onChange={handleChange}>
						{arr?.map((x) => {
							return <MenuItem value={x.id}>{x.nama}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Stok"
						columns={columns}
						data={stoks}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default Stok;
