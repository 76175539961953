import {
	Button,
	CardContent,
	CardHeader,
	MenuItem,
	Select,
} from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';

import { getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';
import { useHistory } from 'react-router-dom';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const header = [
	{
		title: 'ID',
		field: 'id',
	},
	{
		title: 'ID Project',
		field: 'project_id',
	},
	{
		title: 'Nama Project',
		field: 'nama_project',
	},
];

const CostIndex = () => {
	const history = useHistory();
	const [monitoring, setMonitoring] = useState([]);
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const arr = new Array(5).fill(0);
	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		const sekarang = new Date();
		const tahun = thn !== '' ? thn : sekarang.getFullYear();
		try {
			const { data } = await getAPI(`/monitorings?year=${tahun}`);
			setMonitoring([
				...data.monitorings.map((x, i) => ({
					id: x.id,
					project_id: x.project.project_id,
					nama_project: x.project.nama,
				})),
			]);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	return (
		<Dashboard>
			<CardHeader
				title="Cost Project"
				action={
					<Select defaultValue={selectedYear} onChange={handleChange}>
						{arr?.map((x, id) => {
							const sekarang = new Date();
							const year = sekarang.getFullYear() - (arr.length - (id + 1));
							return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Cost Project"
						columns={header}
						data={monitoring}
						actions={[
							{
								icon: tableIcons.Add,
								tooltip: 'Tambah Cost Item',
								onClick: (event, rowData) =>
									history.push(`/dashboard/finance/cost/${rowData.id}/item`),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default CostIndex;
