import axios from 'axios';
import { CardContent, CardHeader, MenuItem, Select } from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ListProject = (props) => {
	const history = useHistory();
	const [listProjects, setListProjects] = useState([]);
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const arr = new Array(5).fill(0);
	const columns = [
		{
			title: 'Project ID',
			field: 'project.project_id',
		},
		{
			title: 'Nama Project',
			field: 'nama',
		},
		{
			title: 'Regional',
			field: 'regional',
		},
		{
			title: 'Witel',
			field: 'witel',
		},
		{
			title: 'Customer',
			field: 'customer',
		},
		{
			title: 'Nilai Total DRM',
			field: 'nilai_total_drm',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'idr',
				}).format(data['nilai_total_drm']),
		},
		{
			title: 'Nilai Total Aktual',
			field: 'nilai_total_aktual',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'idr',
				}).format(data['nilai_total_aktual']),
		},
	];

	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		const sekarang = new Date();
		const tahun = thn !== '' ? thn : sekarang.getFullYear();
		try {
			const responses = await axios.all([
				getAPI(`/view_list_projects?year=${tahun}`),
				getAPI(`/projects?year=${tahun}`),
			]);
			const [first, second] = responses.map((x) => x.data);
			const temp = first.view_list_projects.map((item, id) => {
				const foo = second.projects.filter((x) => x.id === item.project.id)[0];
				return {
					...item,
					nama: foo.nama,
					regional: foo.detail_lokasi === '0' ? 'Nasional' : foo.detail_lokasi,
					witel: foo.witel,
					customer: foo.khs.customer.nama,
				};
			});
			setListProjects([...temp]);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	return (
		<Dashboard>
			<CardHeader
				title="List Project"
				action={
					<Select defaultValue={selectedYear} onChange={handleChange}>
						{arr?.map((x, id) => {
							const sekarang = new Date();
							const year = sekarang.getFullYear() - (arr.length - (id + 1));
							return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="List Project"
						columns={columns}
						data={listProjects}
						actions={[
							{
								icon: tableIcons.Eye,
								tooltip: 'Status Project',
								onClick: (event, rowData) =>
									history.push(`/dashboard/project-system/status-project`, {
										projectId: rowData.project.id,
									}),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default ListProject;
