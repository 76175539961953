import { CardContent, CardHeader } from '@material-ui/core'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Visibility,
} from '@material-ui/icons'
import MaterialTable from 'material-table'
import React, { forwardRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getAPI } from '../../../../helper/api-consume.js'
import Dashboard from '../../../../layouts/dashboard.jsx'
import FetchingLayer from '../../../../components/global/fetching-layer.jsx'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const ReportProject = (props) => {
  const history = useHistory()
  const [reports, setReports] = useState([])
  const columns = [
    {
      title: 'ID Project',
      field: 'project.project_id',
    },
    {
      title: 'Nama Project',
      field: 'project.nama',
    },
    {
      title: 'customer',
      field: 'project.khs.customer.nama',
    },
    {
      title: 'Total Nilai DRM',
      field: 'total_nilai_drm',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'idr',
        }).format(data['total_nilai_drm']),
    },
    {
      title: 'Total Nilai Rekon',
      field: 'total_nilai_rekon',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'idr',
        }).format(data['total_nilai_rekon']),
    },
    {
      title: 'Persentase Progress',
      field: 'persentase_status',
      render: (data) =>
        `${Intl.NumberFormat('id').format(data['persentase_status'])} %`,
    },
  ]

  const fetchData = async () => {
    try {
      const { data } = await getAPI('/project_report_views')
      setReports([...data.project_report_views])
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Dashboard>
      <CardHeader title="Project Report" />
      <CardContent>
        <FetchingLayer fetchData={fetchData}>
          <MaterialTable
            icons={tableIcons}
            title="Project Report"
            columns={columns}
            data={reports}
            actions={[
              {
                icon: tableIcons.Eye,
                tooltip: 'Detail Project',
                onClick: (event, rowData) =>
                  history.push(`project/detail`, {
                    projectId: rowData.project.id,
                  }),
              },
            ]}
            options={{
              headerStyle: {
                textAlign: 'center',
              },
              rowStyle: {
                textAlign: 'center',
              },
              actionsColumnIndex: -1,
            }}
          />
        </FetchingLayer>
      </CardContent>
    </Dashboard>
  )
}

export default ReportProject
