import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core'
import React, { Fragment } from 'react'

const useStyle = makeStyles(() => ({
  separator: {
    width: '5px',
    backgroundColor: 'blue',
  },
}))

const FirstRow = ({ progres, separator }) => (
  <TableRow>
    <TableCell align="center" variant="head">
      Persentase Progres
    </TableCell>
    <TableCell align="center">{progres?.persentase_status} %</TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">ID Project</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.project?.project_id}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">BA Rekon</TableCell>
    <TableCell align="center">{progres?.adm_penagihan?.ba_rekon}</TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal BA Rekon</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.tanggal_ba_rekon}
    </TableCell>
    <TableCell align="center"></TableCell>
  </TableRow>
)

const SecondRow = ({ progres, separator }) => (
  <TableRow>
    <TableCell align="center">Tanggal Progres 100%</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.tanggal_progress}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal SP</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.project?.tanggal_surat_pesanan}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">PO</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.project?.nomor_po}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal PO</TableCell>
    <TableCell align="center">
      {progres.adm_penagihan?.project?.tanggal_po}
    </TableCell>
    <TableCell align="center"></TableCell>
  </TableRow>
)

const ThirdRow = ({ progres, separator }) => (
  <TableRow>
    <TableCell align="center">SP</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.project?.nomor_surat_pesanan}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal BAUT</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.tanggal_baut}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">BAST</TableCell>
    <TableCell align="center">{progres?.adm_penagihan?.bast}</TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal BAST</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.tanggal_bast}
    </TableCell>
    <TableCell align="center"></TableCell>
  </TableRow>
)

const FourthRow = ({ progres, separator }) => (
  <TableRow>
    <TableCell align="center">BAUT</TableCell>
    <TableCell align="center">{progres?.adm_penagihan?.baut}</TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal Invoice</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.tanggal_invoice}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Jaminan Pelaksanaan</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.jaminan_pelaksanaan}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal Jaminan Pelaksanaan</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.tanggal_jaminan_pelaksanaan}
    </TableCell>
    <TableCell align="center"></TableCell>
  </TableRow>
)

const FifthRow = ({ progres, separator }) => (
  <TableRow>
    <TableCell align="center">Invoice</TableCell>
    <TableCell align="center">{progres?.adm_penagihan?.invoice}</TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal Jaminan Pemeliharaan</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.tanggal_jaminan_pemeliharaan}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Posisi Dokumen</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.posisi_dokumen}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Kendala</TableCell>
    <TableCell align="center">{progres?.adm_penagihan?.kendala}</TableCell>
    <TableCell align="center"></TableCell>
  </TableRow>
)

const SixthRow = ({ progres, separator }) => (
  <TableRow>
    <TableCell align="center">Jaminan Pemeliharaan</TableCell>
    <TableCell align="center">
      {progres?.adm_penagihan?.jaminan_pemeliharaan}
    </TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Tanggal Cash and Bank Jasa</TableCell>
    <TableCell align="center">{progres?.adm_penagihan?.tanggal_cb}</TableCell>
    <TableCell className={separator}></TableCell>
    <TableCell align="center">Cash and Bank Jasa</TableCell>
    <TableCell align="center">
      {Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'idr',
      }).format(progres?.adm_penagihan?.cash_and_bank)}
    </TableCell>
    <TableCell className={separator}></TableCell>
  </TableRow>
)

const Progres = ({ progres }) => {
  const classes = useStyle()
  return (
    <Fragment>
      <Typography gutterBottom variant="subtitle1">
        Progres Project
      </Typography>
      <Table>
        <TableBody>
          <FirstRow progres={progres} separator={classes.separator} />
          <SecondRow progres={progres} separator={classes.separator} />
          <ThirdRow progres={progres} separator={classes.separator} />
          <FourthRow progres={progres} separator={classes.separator} />
          <FifthRow progres={progres} separator={classes.separator} />
          <SixthRow progres={progres} separator={classes.separator} />
        </TableBody>
      </Table>
      <hr
        style={{
          height: '10px',
          width: '100%',
          backgroundColor: 'blue',
          border: 0,
          margin: '1rem 0',
        }}
      />
    </Fragment>
  )
}

export default Progres
