import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core"
import {
  faAngleDown,
  faAngleUp,
  faCircle,
} from "@fortawesome/free-solid-svg-icons"
import React, { Fragment, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"

const MenuItem = ({ url, title }) => {
  return (
    <ListItem button component={Link} to={url}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faCircle} />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  )
}

const MenuChild = ({ title, icon, isOpen, toggle }) => {
  return (
    <ListItem button onClick={toggle}>
      <ListItemIcon>
        {icon !== undefined ? (
          <FontAwesomeIcon icon={icon} size="lg" />
        ) : (
          <FontAwesomeIcon icon={faCircle} />
        )}
      </ListItemIcon>
      <ListItemText primary={title} />
      <FontAwesomeIcon icon={isOpen ? faAngleUp : faAngleDown} />
    </ListItem>
  )
}

const GrandMenu = ({ menu }) => {
  const { url, title } = menu
  const children = menu?.children
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      {children === undefined ? (
        <MenuItem url={url} title={title} />
      ) : (
        <MenuChild
          title={title}
          isOpen={isOpen}
          toggle={() => setIsOpen(!isOpen)}
        />
      )}
      {children !== undefined && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, idx) => {
              return (
                <ListItem button component={Link} to={child.url}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={faCircle} />
                  </ListItemIcon>
                  <ListItemText primary={child.title} />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      )}
    </Fragment>
  )
}

const Menu = (props) => {
  const { icon, title, children } = props.menu
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Fragment>
      <MenuChild
        title={title}
        icon={icon}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      />
      {children !== undefined && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child, idx) => {
              return <GrandMenu key={idx} menu={child} />
            })}
          </List>
        </Collapse>
      )}
    </Fragment>
  )
}

export default Menu
