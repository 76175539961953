import {
	faCalculator,
	faCogs,
	faDatabase,
	faScroll,
	faSitemap,
} from '@fortawesome/free-solid-svg-icons';

const availableRoute = [
	{
		title: 'material management',
		icon: faCogs,
		children: [
			{
				title: 'material requisition',
				url: '/dashboard/material-management/requisition',
			},
			{
				title: 'inventory',
				children: [
					{ title: 'stok', url: '/dashboard/material-management/stok' },
					{
						title: 'barang masuk',
						url: '/dashboard/material-management/barang-masuk',
					},
					{
						title: 'barang keluar',
						url: '/dashboard/material-management/barang-keluar',
					},
					{
						title: 'pengembalian barang',
						url: '/dashboard/material-management/pengembalian-barang',
					},
				],
			},
		],
	},
	{
		title: 'finance',
		icon: faCalculator,
		children: [
			{
				title: 'permintaan',
				url: '/dashboard/finance/permintaan/',
			},
			{
				title: 'monitoring',
				url: '/dashboard/finance/monitoring/',
			},
			{
				title: 'cost project',
				url: '/dashboard/finance/cost/',
			},
		],
	},
	{
		title: 'project system',
		icon: faSitemap,
		children: [
			{
				title: 'create',
				url: '/dashboard/project-system/project/',
			},
			{
				title: 'list project',
				url: '/dashboard/project-system/list-project',
			},
			{
				title: 'admin penagihan',
				url: '/dashboard/project-system/admin-penagihan',
			},
		],
	},
	{
		title: 'data master',
		icon: faDatabase,
		children: [
			{
				title: 'customer',
				url: '/dashboard/data-master/customer',
			},
			{
				title: 'kontrak harga satuan',
				url: '/dashboard/data-master/khs',
			},
			{
				title: 'mitra',
				url: '/dashboard/data-master/mitra',
			},
			{
				title: 'supplier',
				url: '/dashboard/data-master/supplier',
			},
			{
				title: 'user',
				url: '/dashboard/data-master/user',
			},
			{
				title: 'material',
				url: '/dashboard/data-master/material',
			},
			{
				title: 'gudang',
				url: '/dashboard/data-master/gudang',
			},
		],
	},
	{
		title: 'report',
		icon: faScroll,
		children: [
			{
				title: 'project report',
				url: '/dashboard/report/project',
			},
			{
				title: 'yearly report',
				url: '/dashboard/report/year',
			},
		],
	},
];

const availableRoles = {
	manager: [
		{
			title: 'material management',
			children: [],
		},
		{
			title: 'finance',
			children: [],
		},
		{
			title: 'project system',
			children: [],
		},
		{
			title: 'data master',
			children: [
				{
					title: 'customer',
					url: '/dashboard/data-master/customer',
				},
				{
					title: 'kontrak harga satuan',
					url: '/dashboard/data-master/khs',
				},
				{
					title: 'mitra',
					url: '/dashboard/data-master/mitra',
				},
				{
					title: 'supplier',
					url: '/dashboard/data-master/supplier',
				},
				{
					title: 'material',
					url: '/dashboard/data-master/material',
				},
				{
					title: 'gudang',
					url: '/dashboard/data-master/gudang',
				},
			],
		},
		{
			title: 'report',
			children: [],
		},
	],
	direktur: [
		{
			title: 'material management',
			children: [],
		},
		{
			title: 'finance',
			children: [],
		},
		{
			title: 'project system',
			children: [],
		},
		{
			title: 'data master',
			children: [
				{
					title: 'customer',
					url: '/dashboard/data-master/customer',
				},
				{
					title: 'kontrak harga satuan',
					url: '/dashboard/data-master/khs',
				},
				{
					title: 'mitra',
					url: '/dashboard/data-master/mitra',
				},
				{
					title: 'supplier',
					url: '/dashboard/data-master/supplier',
				},
				{
					title: 'material',
					url: '/dashboard/data-master/material',
				},
			],
		},
		{
			title: 'report',
			children: [],
		},
	],
	'project manager': [
		{
			title: 'material management',
			children: [],
		},
		{
			title: 'finance',
			children: [],
		},
		{
			title: 'project system',
			children: [],
		},
		{
			title: 'data master',
			children: [
				{
					title: 'customer',
					url: '/dashboard/data-master/customer',
				},
				{
					title: 'kontrak harga satuan',
					url: '/dashboard/data-master/khs',
				},
				{
					title: 'mitra',
					url: '/dashboard/data-master/mitra',
				},
				{
					title: 'supplier',
					url: '/dashboard/data-master/supplier',
				},
				{
					title: 'material',
					url: '/dashboard/data-master/material',
				},
			],
		},
		{
			title: 'report',
			children: [],
		},
	],
	'site manager': [
		{
			title: 'material management',
			children: [
				{
					title: 'material requisition',
					url: '/dashboard/material-management/requisition',
				},
				{
					title: 'inventory',
					children: [
						{ title: 'stok', url: '/dashboard/material-management/stok' },
					],
				},
			],
		},
		{
			title: 'finance',
			children: [],
		},
		{
			title: 'project system',
			children: [],
		},
	],
	'admin finance': [
		{
			title: 'material management',
			children: [
				{
					title: 'inventory',
					children: [
						{ title: 'stok', url: '/dashboard/material-management/stok' },
					],
				},
			],
		},
		{
			title: 'finance',
			children: [],
		},
		{
			title: 'project system',
			children: [],
		},
		{
			title: 'data master',
			children: [
				{
					title: 'customer',
					url: '/dashboard/data-master/customer',
				},
				{
					title: 'kontrak harga satuan',
					url: '/dashboard/data-master/khs',
				},
				{
					title: 'mitra',
					url: '/dashboard/data-master/mitra',
				},
				{
					title: 'supplier',
					url: '/dashboard/data-master/supplier',
				},
				{
					title: 'material',
					url: '/dashboard/data-master/material',
				},
			],
		},
	],
	'admin finance pusat': [
		{
			title: 'project system',
			children: [
				{
					title: 'admin penagihan',
					url: '/dashboard/project-system/admin-penagihan',
				},
			],
		},
	],
	admin: [
		{
			title: 'finance',
			children: [
				{
					title: 'permintaan',
					url: '/dashboard/finance/permintaan/',
				},
			],
		},
		{
			title: 'project system',
			children: [],
		},
		{
			title: 'data master',
			children: [
				{
					title: 'customer',
					url: '/dashboard/data-master/customer',
				},
				{
					title: 'kontrak harga satuan',
					url: '/dashboard/data-master/khs',
				},
				{
					title: 'mitra',
					url: '/dashboard/data-master/mitra',
				},
				{
					title: 'supplier',
					url: '/dashboard/data-master/supplier',
				},
				{
					title: 'material',
					url: '/dashboard/data-master/material',
				},
			],
		},
	],
	gudang: [
		{ title: 'material management', children: [] },
		{
			title: 'project system',
			children: [
				{
					title: 'list project',
					url: '/dashboard/project-system/list-project',
				},
			],
		},
		{
			title: 'data master',
			children: [
				{
					title: 'mitra',
					url: '/dashboard/data-master/mitra',
				},
			],
		},
	],
	'akun it': [],
};

const filterByRole = ({
	arr = availableRoute,
	roles = availableRoles,
	role = '',
}) => {
	let result = [];

	if (role !== '') {
		const filterBy = roles[role];
		if (filterBy.length > 0) {
			for (const item of filterBy) {
				const base = arr.find((a) => a.title === item.title);
				const temp = item.children.length > 0 ? item : base;
				result = [...result, { ...temp }];
			}
		} else {
			result = [...result, ...arr];
		}
		return result;
	} else {
		return result;
	}
};

export { availableRoles, availableRoute, filterByRole };
