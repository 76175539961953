import {
	Button,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogTitle,
} from '@material-ui/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { deleteAPI, getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const header = [
	{
		title: 'No',
		field: 'no',
	},
	{
		title: 'Cutsomer',
		field: 'customer',
	},
	{
		title: 'Nomor Kontrak',
		field: 'nomor_kontrak',
	},
	{
		title: 'Tanggal Kontrak',
		field: 'tanggal_kontrak',
	},
	{
		title: 'Tanggal Berakhir',
		field: 'tanggal_berakhir',
	},
	{
		title: 'Regional',
		field: 'regional',
		render: (data) => (data['regional'] > 0 ? data['regional'] : 'Nasional'),
	},
	{
		title: 'Paket',
		field: 'paket',
	},
];

const KHS = () => {
	const history = useHistory();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(true);
	const [khs, setKhs] = useState([]);

	const fetchData = async () => {
		try {
			const { data } = await getAPI('/khss');
			setKhs([
				...data.khss.map((x, i) => ({
					id: x.id,
					no: i + 1,
					customer: x.customer.nama,
					nomor_kontrak: x.nomor_kontrak,
					tanggal_kontrak: x.tanggal_kontrak,
					tanggal_berakhir: x.tanggal_berakhir,
					regional: x.regional,
					paket: `Paket ${x.paket}`,
					file: x.file,
				})),
			]);
		} catch (error) {
			console.error(error);
		}
	};

	const handleRemove = (id) => {
		setIsOpen(!isOpen);
		setSelectedId(id);
	};

	const removeKHS = async (id) => {
		try {
			await deleteAPI('/khss', id);
			await fetchData();
			setIsOpen(!isOpen);
		} catch (error) {
			console.error();
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Kontrak Harga Satuan"
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faPlus} size="sm" />}
						component={RouterLink}
						to="khs/create"
					>
						tambah
					</Button>
				}
			/>
			<CardContent>
				<Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)}>
					<DialogTitle>Yakin Ingin Menghapus Data Ini ?</DialogTitle>
					<DialogActions>
						<Button color="primary" onClick={() => setIsOpen(!isOpen)}>
							Tidak
						</Button>
						<Button color="primary" onClick={() => removeKHS(selectedId)}>
							Ya
						</Button>
					</DialogActions>
				</Dialog>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="KHS"
						columns={header}
						data={khs}
						actions={[
							{
								icon: tableIcons.Add,
								tooltip: 'Tambah Uraian Pekerjaan',
								onClick: (event, rowData) =>
									history.push(`khs/${rowData.id}/uraian-pekerjaan`),
							},
							{
								icon: tableIcons.Eye,
								tooltip: 'Lihat Dokumen',
								onClick: (event, rowData) =>
									window.open(
										`${process.env.REACT_APP_API_BASEURL}/files/khs/${rowData.file}`,
										'_blank'
									),
							},
							{
								icon: tableIcons.Edit,
								tooltip: 'Edit KHS',
								onClick: (event, rowData) => history.push(`khs/${rowData.id}`),
							},
							{
								icon: tableIcons.Delete,
								tooltip: 'Hapus KHS',
								onClick: (event, rowData) => handleRemove(rowData.id),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default KHS;
