import {
	Button,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	DialogTitle,
	MenuItem,
	Select,
} from '@material-ui/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { deleteAPI, getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const header = [
	{
		title: 'ID',
		field: 'id',
	},
	{
		title: 'Nama',
		field: 'name',
	},
	{
		title: 'Permintaan',
		field: 'ke',
	},
	{
		title: 'Tanggal',
		field: 'tanggal',
	},
];

const PermintaanIndex = () => {
	const history = useHistory();

	const [isOpen, setIsOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(true);
	const [permintaan, setPermintaan] = useState([]);
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const arr = new Array(5).fill(0);
	const [loading, setLoading] = useState(true);

	const fetchData = async (thn = '') => {
		try {
			const sekarang = new Date();
			const tahun = thn !== '' ? thn : sekarang.getFullYear();
			const { data } = await getAPI(`/permintaans?year=${tahun}`);
			setPermintaan([
				...data.permintaans.map((x, i) => ({
					id: x.id,
					name: x.user.name,
					ke: `Minggu Ke- ${x.ke}`,
					tanggal: x.tanggal,
					file: x.file,
				})),
			]);
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const removePermintaan = async (id) => {
		try {
			await deleteAPI('/permintaans', id);
			await fetchData();
			setIsOpen(!isOpen);
		} catch (error) {
			console.error();
		}
	};

	const handleRemove = (id) => {
		setIsOpen(!isOpen);
		setSelectedId(id);
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	return (
		<Dashboard>
			<CardHeader
				title="Permintaan"
				action={
					<>
						<Select
							defaultValue={selectedYear}
							onChange={handleChange}
							style={{ marginRight: '16px' }}
						>
							{arr?.map((x, id) => {
								const sekarang = new Date();
								const year = sekarang.getFullYear() - (arr.length - (id + 1));
								return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
							})}
						</Select>
						<Button
							variant="contained"
							color="primary"
							endIcon={<FontAwesomeIcon icon={faPlus} size="sm" />}
							component={RouterLink}
							to="/dashboard/finance/permintaan/create"
						>
							tambah
						</Button>
					</>
				}
			/>
			<CardContent>
				<Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)}>
					<DialogTitle>Yakin Ingin Menghapus Data Ini ?</DialogTitle>
					<DialogActions>
						<Button color="primary" onClick={() => setIsOpen(!isOpen)}>
							Tidak
						</Button>
						<Button
							color="primary"
							onClick={() => removePermintaan(selectedId)}
						>
							Ya
						</Button>
					</DialogActions>
				</Dialog>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Permintaan"
						columns={header}
						data={permintaan}
						actions={[
							{
								icon: tableIcons.Add,
								tooltip: 'Tambah Operasional',
								onClick: (event, rowData) =>
									history.push(
										`/dashboard/finance/permintaan/${rowData.id}/operasional`
									),
							},
							{
								icon: tableIcons.Eye,
								tooltip: 'Lihat Dokumen',
								onClick: (event, rowData) =>
									window.open(
										`${process.env.REACT_APP_API_BASEURL}/files/permintaan/${rowData.file}`,
										'_blank'
									),
							},
							{
								icon: tableIcons.Edit,
								tooltip: 'Edit Permintaan',
								onClick: (event, rowData) =>
									history.push(`/dashboard/finance/permintaan/${rowData.id}`),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default PermintaanIndex;
