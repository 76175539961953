import axios from 'axios';
import { Button, CardContent, CardHeader, TextField } from '@material-ui/core';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Refresh,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import {
	deleteAPI,
	getAPI,
	postAPI,
	putAPI,
} from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Renew: forwardRef((props, ref) => <Refresh {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MaterialDetailRequisition = (props) => {
	const history = useHistory();
	const { requisitionId } = history.location.state;
	const [detailRequisitions, setDetailRequisitions] = useState([]);
	const [requisitions, setRequisitions] = useState({});
	const [materials, setMaterials] = useState({});
	const [gudangs, setGudangs] = useState({});
	const columns = [
		{
			title: 'Material Requisition ID',
			field: 'material_requisition_id',
			lookup: requisitions,
			editable: 'never',
		},
		{
			title: 'Designator',
			field: 'material_id',
			lookup: materials,
			editComponent: ({ onChange, value }) => (
				<Autocomplete
					id="combo-box-demo"
					options={[
						...Object.entries(materials).map((item) => ({
							id: item[0],
							title: item[1],
						})),
					]}
					getOptionLabel={(option) => option.title}
					value={value}
					onChange={(event, newValue) => onChange(newValue.id)}
					renderInput={(params) => <TextField {...params} />}
				/>
			),
		},
		{
			title: 'Uraian Pekerjaan',
			field: 'material.nama',
			editable: 'never',
		},
		{
			title: 'Satuan',
			field: 'material.satuan',
			editable: 'never',
		},
		{
			title: 'Volume',
			field: 'volume',
		},
		{
			title: 'Gudang',
			field: 'gudang_id',
			lookup: gudangs,
		},
		{
			title: 'Keterangan',
			field: 'keterangan',
		},
	];

	const fetchData = async () => {
		try {
			const responses = await axios.all([
				getAPI('/detail_material_requisitions'),
				getAPI('/material_requisitions'),
				getAPI('/materials'),
				getAPI('/gudangs'),
			]);
			const [first, second, third, fourth] = responses.map((item) => item.data);
			if (first.detail_material_requisitions !== null) {
				const firstTemp = first.detail_material_requisitions.map((item) => {
					const material = third.materials.filter(
						(x) => x.designator === item.material.designator
					)[0];
					const gudang = fourth.gudangs.filter(
						(x) => x.nama === item.gudang_id.nama
					)[0];
					const material_id = material.id;
					return {
						...item,
						material_requisition_id: item.material_requisition.id,
						material_id,
						gudang_id: gudang.id,
					};
				});

				setDetailRequisitions([
					...firstTemp.filter(
						(item) => item.material_requisition_id === requisitionId
					),
				]);
			}

			if (second.material_requisitions !== null) {
				const secondTemp = second.material_requisitions.filter(
					(item) => item.id === requisitionId
				);
				const requisitionsTemp = secondTemp.reduce((acc, cur) => {
					acc[cur.id] = cur.material_requisition_id;
					return acc;
				}, {});
				setRequisitions({ ...requisitionsTemp });
			}

			if (third.materials !== null) {
				const temp = third.materials.reduce((acc, cur) => {
					acc[cur.id] = cur.designator;
					return acc;
				}, {});
				console.log(temp);
				setMaterials({ ...temp });
			}
			const fourthTemp = fourth.gudangs.reduce((acc, cur) => {
				acc[cur.id] = cur.nama;
				return acc;
			}, {});
			delete fourthTemp[0];
			setGudangs({ ...fourthTemp });
		} catch (error) {
			console.error(error);
		}
	};

	const addItem = async (newData) => {
		try {
			await postAPI(`/detail_material_requisitions`, {
				...newData,
				material_requisition_id: requisitionId,
			});
			await fetchData();
		} catch (error) {
			alert('Error saat tambah data');
			console.error(error);
		}
	};

	const updateItem = async (newData, oldData) => {
		try {
			await putAPI(`/detail_material_requisitions/${oldData.id}`, {
				...newData,
				material_requisition_id: requisitionId,
			});
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	const refreshData = async (rowData) => {
		const body = { ...rowData };
		try {
			await putAPI(`/detail_material_requisitions/${rowData.id}`, body);
			await fetchData();
			await alert('Success add to fulfillment');
		} catch (error) {
			alert('Error saat refresh data');
			console.error(error);
		}
	};

	const removeItem = async (rowData) => {
		try {
			await deleteAPI('/detail_material_requisitions', rowData.id);
			await fetchData();
		} catch (error) {
			alert('Error saat menghapus data');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Material Detail Requisition"
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faReply} />}
						onClick={() => history.goBack()}
					>
						Kembali
					</Button>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Material Detail Requisition"
						columns={columns}
						data={detailRequisitions}
						editable={{
							onRowAdd: (newData) => addItem(newData),
							onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
							onRowDelete: (oldData) => removeItem(oldData),
						}}
						actions={[
							{
								icon: tableIcons.Renew,
								tooltip: 'Refresh Fulfillment',
								onClick: (event, rowData) => refreshData(rowData),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default MaterialDetailRequisition;
