import axios from 'axios';
import {
	Button,
	CardContent,
	CardHeader,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { faFileExport, faReply } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import { getAPI } from '../../../../helper/api-consume';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer';

const ProjectDetail = () => {
	const history = useHistory();
	const { id } = useParams();
	const [project, setProject] = useState({});
	const [khss, setKhss] = useState([]);

	const handleChange = ({ target }) => {
		const value =
			target.type === 'checkbox'
				? target.checked
				: target.type === 'file'
				? target.files[0]
				: target.value;
		setProject({ ...project, [target.name]: value });
	};

	const fetchData = async () => {
		try {
			const responses = await axios.all([
				getAPI('/khss'),
				getAPI(`/projects/${id}`),
			]);
			const [first, second] = responses.map((x) => x.data);
			setKhss([...first.khss]);
			setProject({ ...second.projects[0] });
		} catch (error) {}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Detail Project"
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
						onClick={() => history.goBack()}
					>
						kembali
					</Button>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<Grid container justify="center">
						<Grid item xs={12} md={8}>
							<Button
								variant="contained"
								color="primary"
								components="a"
								href={`${process.env.REACT_APP_API_BASEURL}/files/boq/${project.file_boq}`}
								endIcon={<FontAwesomeIcon icon={faFileExport} />}
							>
								file boq
							</Button>
							<Button
								variant="contained"
								color="primary"
								components="a"
								href={`${process.env.REACT_APP_API_BASEURL}/files/rekon/${project.file_rekon}`}
								endIcon={<FontAwesomeIcon icon={faFileExport} />}
								style={{ margin: '0 .5rem' }}
							>
								file rekon
							</Button>
							<form>
								<TextField
									fullWidth
									disabled
									margin="normal"
									label="Project ID"
									name="project_id"
									type="text"
									value={project.project_id}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<FormControl fullWidth margin="normal">
									<InputLabel id="khs_label">KHS</InputLabel>
									<Select
										disabled
										labelId="khs_label"
										name="khs_id"
										value={project.khs !== undefined ? project.khs.id : ''}
										InputLabelProps={{ shrink: true }}
										onChange={handleChange}
									>
										{khss.map((khs, id) => (
											<MenuItem key={id} value={khs.id}>
												{`${khs.nomor_kontrak} - Paket ${khs.paket}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									fullWidth
									disabled
									margin="normal"
									label="Nama Project"
									name="nama"
									type="text"
									value={project.nama}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									disabled
									margin="normal"
									label="Witel"
									name="witel"
									type="text"
									value={project.witel}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									disabled
									margin="normal"
									label="Detail Lokasi"
									name="detail_lokasi"
									multiline
									type="text"
									value={project.detail_lokasi}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									disabled
									margin="normal"
									label="No Surat Pesanan"
									name="nomor_surat_pesanan"
									type="text"
									value={project.nomor_surat_pesanan}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									disabled
									margin="normal"
									type="date"
									label="Tanggal Surat Pesanan"
									name="tanggal_surat_pesanan"
									InputLabelProps={{ shrink: true }}
									value={project.tanggal_surat_pesanan}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									disabled
									margin="normal"
									label="No PO"
									name="nomor_po"
									type="text"
									value={project.nomor_po}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									disabled
									margin="normal"
									type="date"
									label="Tanggal PO"
									name="tanggal_po"
									InputLabelProps={{ shrink: true }}
									value={project.tanggal_po}
									onChange={handleChange}
								/>
							</form>
						</Grid>
					</Grid>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default ProjectDetail;
