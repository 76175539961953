import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core"
import {
  faEye,
  faEyeSlash,
  faLock,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons"
import Cookies from "js-cookie"
import React, { useContext, useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useHistory, useLocation } from "react-router-dom"
import imageLogin from "./assets/worker.jpg"

import { AuthContext } from "../../App.js"
import { login } from "../../helper/auth"
import AuthLayout from "../../layouts/auth.jsx"
import { useFormInput } from "../../helper/state.js"
import "./login.css"

const Login = () => {
  const [auth, dispatch] = useContext(AuthContext)
  const history = useHistory()
  const location = useLocation()
  const user = useFormInput({})
  const [isShow, setIsShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { from } = location.state || { from: { pathname: "/dashboard" } }
  const raw = Cookies.get("auth")
  const minute = 60 * 1000
  const now = new Date()

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      await login({ ...user.value })
      const first = new Date(now.getTime() + 60 * minute)
      const second = new Date(now.getTime() + 70 * minute)
      Cookies.set("auth", JSON.stringify(second), { expires: second })
      dispatch({
        type: "LOGIN",
        payload: {
          expires: first,
          maxAges: second,
        },
      })
      history.replace(from)
    } catch (error) {
      console.error(error)
    }
  }

  const checkAuth = async () => {
    if (raw !== undefined) {
      const converted = JSON.parse(raw)
      const cookie = new Date(converted)
      const result = cookie - now
      if (result > 0) {
        try {
          const first = new Date(now.getTime() + 10 * minute)
          const payload = { expires: first, maxAges: cookie }
          dispatch({ type: "LOGIN", payload })
          history.replace(from)
        } catch (error) {
          console.error(error)
        }
      } else {
        Cookies.remove("auth")
      }
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (!auth.isAuthenticate) {
      checkAuth()
    }
    setIsLoading(false)
  }, [])

  return (
    <div className="containerBG">
      <AuthLayout>
        {isLoading ? (
          ""
        ) : (
          <Card>
            <div className="titleContent">
              <Typography variant="h5">Welcome to Tripola System</Typography>
              <Typography variant="h5">SDM UNGGUL</Typography>
            </div>
            <CardContent>
              <div className="imgLogin">
                <img src={imageLogin} alt="" />
              </div>
              <form onSubmit={handleSubmit}>
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  autoComplete="email"
                  type="text"
                  onChange={user.handleChange}
                  required
                />
                <TextField
                  name="password"
                  label="Password"
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  type={isShow ? "text" : "password"}
                  onChange={user.handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setIsShow(!isShow)}
                        >
                          <FontAwesomeIcon icon={isShow ? faEyeSlash : faEye} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                />
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: "1rem" }}
                  endIcon={<FontAwesomeIcon icon={faSignInAlt} />}
                >
                  Login
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </AuthLayout>
    </div>
  )
}

export default Login
