import {
	CardContent,
	CardHeader,
	MenuItem,
	Select,
	Typography,
	makeStyles,
} from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import axios from 'axios';
import MaterialTable from 'material-table';
import React, { forwardRef, useEffect, useState } from 'react';

import { getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
	bgCard: {
		background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
		width: '37%',
		borderRadius: 4,
		padding: '1rem',
		color: '#fff',
		marginBottom: 16,
	},
}));

const YearlyReport = (props) => {
	const classes = useStyles();
	const [projects, setProjects] = useState([]);
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const [totals, setTotals] = useState([]);
	const arr = new Array(5).fill(0);
	const columns = [
		{
			title: 'ID Project',
			field: 'project.project_id',
		},
		{
			title: 'Nama Project',
			field: 'project.nama',
		},
		{
			title: 'Customer',
			field: 'project.khs.customer.nama',
		},
		{
			title: 'Regional',
			field: 'project.detail_lokasi',
			render: (data) =>
				data['project']['detail_lokasi'] === '0'
					? 'Nasional'
					: data['project']['detail_lokasi'],
		},
		{
			title: 'Witel',
			field: 'project.witel',
		},
		{
			title: 'Total Nilai Rekon',
			field: 'total_nilai_rekon',
			render: (data) =>
				Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'idr',
				}).format(data['total_nilai_rekon']),
		},
		{
			title: 'Nilai Cash and Bank Jasa',
			field: 'adm_penagihan.cash_and_bank',
			render: (data) =>
				Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'idr',
				}).format(data.adm_penagihan.cash_and_bank),
		},
		{
			title: 'Total Nilai Termin',
			field: 'total_nilai_termin',
			render: (data) =>
				Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'idr',
				}).format(data['total_nilai_termin']),
		},
		{
			title: 'Total Nilai Cost Project',
			field: 'total_nilai_cost_project',
			render: (data) =>
				Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'idr',
				}).format(data['total_nilai_cost_project']),
		},
		{
			title: 'Pendapatan',
			field: 'pendapatan',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'idr',
				}).format(data['pendapatan']),
			cellStyle: (data) => ({
				backgroundColor: data > 0 ? 'lightblue' : 'red',
				color: data > 0 ? 'black' : 'white',
			}),
		},
	];

	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		try {
			const sekarang = new Date();
			const tahun = thn !== '' ? thn : sekarang.getFullYear();
			const url = `/laporans/project/${tahun}`;
			const [first, second] = await axios.all([
				getAPI(url),
				getAPI(`/operasionals?year=${tahun}`),
			]);
			const { data } = first;
			if (data.laporan_project_tahunans !== null) {
				setProjects([...data.laporan_project_tahunans]);
				const {
					nilaiRekon,
					nilaiCashAndBank,
					nilaiTermin,
					nilaiCostProject,
				} = data?.laporan_project_tahunans?.reduce(
					(acc, cur) => {
						const {
							total_nilai_rekon,
							total_nilai_termin,
							total_nilai_cost_project,
							adm_penagihan: { cash_and_bank },
						} = cur;
						acc['nilaiRekon'] += total_nilai_rekon;
						acc['nilaiCashAndBank'] += cash_and_bank;
						acc['nilaiTermin'] += total_nilai_termin;
						acc['nilaiCostProject'] += total_nilai_cost_project;
						return acc;
					},
					{
						nilaiRekon: 0,
						nilaiCashAndBank: 0,
						nilaiTermin: 0,
						nilaiCostProject: 0,
					}
				);
				const nilaiCostGeneral = second?.data?.operasionals?.reduce(
					(acc, cur) => acc + parseInt(cur['nilai_keperluan']),
					0
				);
				setTotals([
					{
						'Total Nilai Invoice': nilaiRekon,
					},
					{
						'Total Nilai Cash and Bank Jasa': nilaiCashAndBank,
					},
					{
						'Total Nilai Termin': nilaiTermin,
					},
					{
						'Total Nilai Cost Project': nilaiCostProject,
					},
					{
						'Total Nilai Cost General': nilaiCostGeneral,
					},
					{
						Saldo:
							nilaiCashAndBank -
							nilaiTermin -
							nilaiCostProject -
							nilaiCostGeneral,
					},
				]);
			} else {
				setProjects([]);
				setTotals([]);
			}
			setLoading(false);
		} catch (error) {
			alert(error);
			console.error(error);
		}
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	return (
		<Dashboard>
			<CardHeader
				title={
					totals.length > 0 && (
						<div className={classes.bgCard}>
							{totals?.map((item, id) => {
								const val = new Intl.NumberFormat('id-ID', {
									style: 'currency',
									currency: 'IDR',
								}).format(Object.values(item)[0]);
								const key = Object.keys(item)[0];
								return (
									<Typography key={id} variant="h6" style={{ flexGrow: 1 }}>
										{`${key}: ${val}`}
									</Typography>
								);
							})}
						</div>
					)
				}
				action={
					<Select defaultValue={selectedYear} onChange={handleChange}>
						{arr?.map((x, id) => {
							const sekarang = new Date();
							const year = sekarang.getFullYear() - (arr.length - (id + 1));
							return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				{loading ? (
					'loading'
				) : (
					<MaterialTable
						icons={tableIcons}
						title="Laporan Tahunan"
						columns={columns}
						data={projects}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: {
								textAlign: 'center',
							},
							exportButton: true,
						}}
					/>
				)}
			</CardContent>
		</Dashboard>
	);
};

export default YearlyReport;
