import axios from 'axios';
import {
	Button,
	CircularProgress,
	CardContent,
	CardHeader,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@material-ui/core';
import { faPaperPlane, faReply } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';

import { getAPI } from '../../../../helper/api-consume';
import { putAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer';

const ProjectEdit = () => {
	const history = useHistory();
	const { id } = useParams();
	const [project, setProject] = useState({});
	const [khss, setKhss] = useState([]);
	const [attachFile, setAttachFile] = useState(false);
	const detailLokasi = [0, 1, 2, 3, 4, 5, 6, 7];

	const handleChange = ({ target }) => {
		const value =
			target.type === 'checkbox'
				? target.checked
				: target.type === 'file'
				? target.files[0]
				: target.value;
		setProject({ ...project, [target.name]: value });
	};

	const fetchData = async () => {
		try {
			const responses = await axios.all([
				getAPI('/khss'),
				getAPI(`/projects/${id}`),
			]);
			const [first, second] = responses.map((x) => x.data);
			setKhss([...first.khss]);
			setProject({ ...second.projects[0], khs_id: second.projects[0].khs.id });
		} catch (error) {}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setAttachFile(!attachFile);
		try {
			await putAPI(`/projects/${id}`, { ...project });
			setAttachFile(!attachFile);
			history.goBack();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Tambah Project"
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
						onClick={() => history.goBack()}
					>
						kembali
					</Button>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<Grid container justify="center">
						<Grid item xs={12} md={8}>
							<form onSubmit={handleSubmit} encType="multipart/form-data">
								<TextField
									fullWidth
									margin="normal"
									label="Project ID"
									name="project_id"
									type="text"
									value={project.project_id}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<FormControl fullWidth margin="normal">
									<InputLabel id="khs_label">KHS</InputLabel>
									<Select
										labelId="khs_label"
										name="khs_id"
										value={project.khs !== undefined ? project.khs_id : -1}
										onChange={handleChange}
									>
										{khss.map((khs, id) => (
											<MenuItem key={id} value={khs.id}>
												{`${khs.nomor_kontrak} - Paket ${khs.paket}`}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									fullWidth
									margin="normal"
									label="Nama Project"
									name="nama"
									type="text"
									value={project.nama}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									margin="normal"
									label="Witel"
									name="witel"
									type="text"
									value={project.witel}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<FormControl fullWidth margin="normal">
									<InputLabel id="detail_lokasi">Regional</InputLabel>
									<Select
										labelId="detail_lokasi"
										name="detail_lokasi"
										value={project.detail_lokasi}
										onChange={project.handleChange}
									>
										{detailLokasi.map((lokasi, id) => (
											<MenuItem key={id} value={lokasi}>
												{lokasi === 0 ? 'Nasional' : lokasi}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									fullWidth
									margin="normal"
									label="No Surat Pesanan"
									name="nomor_surat_pesanan"
									type="text"
									value={project.nomor_surat_pesanan}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									margin="normal"
									type="date"
									label="Tanggal Surat Pesanan"
									name="tanggal_surat_pesanan"
									InputLabelProps={{ shrink: true }}
									value={project.tanggal_surat_pesanan}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									margin="normal"
									label="No PO"
									name="nomor_po"
									type="text"
									value={project.nomor_po}
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									margin="normal"
									type="date"
									label="Tanggal PO"
									name="tanggal_po"
									InputLabelProps={{ shrink: true }}
									value={project.tanggal_po}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									margin="normal"
									label="File BOQ DRM (.PDF)"
									name="file_boq"
									type="file"
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<TextField
									fullWidth
									margin="normal"
									label="File BOQ Rekon (.PDF)"
									name="file_rekon"
									type="file"
									InputLabelProps={{ shrink: true }}
									onChange={handleChange}
								/>
								<FormControl fullWidth margin="normal">
									<Button
										margin="normal"
										type="submit"
										variant="contained"
										color="primary"
										endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
									>
										{attachFile ? (
											<CircularProgress color="secondary" />
										) : (
											'submit'
										)}
									</Button>
								</FormControl>
							</form>
						</Grid>
					</Grid>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default ProjectEdit;
