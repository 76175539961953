import { useEffect, useRef, useState } from "react"

export const useFormInput = (initialState) => {
  const [value, setValue] = useState(initialState)

  const handleChange = ({ target }) => {
    let val =
      target.type === "checkbox"
        ? target.checked
        : target.type === "file"
        ? target.files[0]
        : target.value
    setValue({ ...value, [target.name]: val })
  }

  return { value, handleChange }
}

export const useEffectOnce = (cb) => {
  const loading = useRef(false)
  useEffect(() => {
    if (!loading.current) {
      cb()
      loading.current = true
    }
  })
}
