import React, { forwardRef, useState } from 'react';
import Dashboard from '../../../../layouts/dashboard';
import { CardContent, CardHeader, Card } from '@material-ui/core';
import FetchingLayer from "../../../../components/global/fetching-layer.jsx"

// material component
import MaterialTable from 'material-table';

//icon import material ui
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import {
    deleteAPI,
    getAPI,
    postAPI,
    putAPI,
} from "../../../../helper/api-consume.js"


const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



const Mitra = (props) => {
    const [mitras, setMitras] = useState([]);
    const [state, setState] = React.useState({
        columns: [
            { title: 'ID', field: 'id', editable: "never" },
            { title: 'Nama', field: 'nama' },
            { title: 'Alamat', field: 'alamat' },
            {title: 'No. Rekening', field: 'no_rekening'},
            {title: 'Nama Rekening', field: 'nama_rekening'},
            {title: 'Nama Bank', field: 'nama_bank'},
        ],
        data: [
        ],
    });

    const fetchData = async() => {
        try {
            const {data} = await getAPI(`/mitras`);
            setMitras(data.mitras);
            
        } catch (error) {
            console.error(error);
            
        }

    }

    const addData = async (newData)=>{
        try {
            const { data } = await postAPI("/mitras", newData)
            console.log(data);
            fetchData();
        } catch (e) {
            console.error(e)
        }

    }

    const updateData =async (newData, oldData)=> {
        try {
            const { data } = await putAPI(`/mitras/${newData.id}`, newData)
            console.log(data)
            setMitras((mitras) => [
              ...mitras.map((x) => (x.id !== newData.id ? x : newData)),
            ])
          } catch (e) {
            console.error(e)
          }

    }

    const deleteData = async (oldData) => {
        try {
            console.log(oldData.id);
            const { data } = await deleteAPI(`/mitras`,oldData.id)
            fetchData();
            console.log(data)
        } catch (e) {
            console.error(e)
        }

    }

    return (
        <Dashboard >
        <Card>
            <CardHeader title="Mitra" />
            <CardContent>
                <FetchingLayer fetchData={fetchData}>
                    <MaterialTable
                        icons={tableIcons}
                        title="Tabel Mitra"
                        columns={state.columns}
                        data={mitras}
                        options={{
                            headerStyle: {
                                textAlign: "center",
                            },
                            rowStyle: {
                                textAlign: "center",
                            },
                            actionsColumnIndex: -1,
                        }}
                        editable={{
                            onRowAdd: (newData) => addData(newData),
                            onRowUpdate: (newData, oldData) =>updateData(newData, oldData),
                            onRowDelete: (oldData) => deleteData(oldData),
                        }}
                    />
                </FetchingLayer>

            </CardContent>
        </Card>

    </Dashboard>
      );
}
 
export default Mitra;