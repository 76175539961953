import axios from 'axios';
import {
	CardContent,
	CardHeader,
	TextField,
	makeStyles,
	MenuItem,
	Select,
} from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	DeviceHub,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
	deleteAPI,
	getAPI,
	postAPI,
	putAPI,
} from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	DeviceHub: forwardRef((props, ref) => <DeviceHub {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
};
const useStyles = makeStyles((theme) => ({
	bgCard: {
		background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
		width: '30%',
		borderRadius: 4,
		padding: '1rem',
		color: '#fff',
		marginBottom: 16,
	},
}));

const Monitoring = (props) => {
	const history = useHistory();
	const classes = useStyles();
	const [monitorings, setMonitorings] = useState([]);
	const [projects, setProjects] = useState({});
	const [mitras, setMitras] = useState({});
	const [boq, setBoq] = useState([]);
	const [rekon, setRekon] = useState([]);
	const [userDetail, setUserDetail] = useState({});
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const arr = new Array(5).fill(0);
	const kesepakatan = {
		persentase: 'Persentase',
		harga_satuan: 'Harga Satuan',
	};

	const columns = [
		{
			title: 'ID',
			field: 'id',
			editable: 'never',
		},
		{
			title: 'ID Project',
			field: 'project_id',
			lookup: projects,
			editComponent: ({ onChange, value }) => (
				<Autocomplete
					id="combo-box-demo"
					options={[
						...Object.entries(projects).map((item) => ({
							id: item[0],
							title: item[1],
						})),
					]}
					getOptionLabel={(option) => option.title}
					value={value}
					onChange={(event, newValue) => onChange(newValue.id)}
					renderInput={(params) => <TextField {...params} />}
				/>
			),
		},
		{
			title: 'Nama Project',
			field: 'nama_project',
			editable: 'never',
		},
		{
			title: 'Nama Mitra',
			field: 'nama_mitra',
			lookup: mitras,
		},
		{
			title: 'Kesepakatan',
			field: 'kesepakatan',
			lookup: kesepakatan,
		},
		{
			title: 'Persentase (%)',
			field: 'persentase_view',
			emptyValue: '0',
			render: (rowData) => rowData.persentase_view + ' %',
		},
		{
			title: 'Nilai DRM Material',
			field: 'nilai_drm_material',
			editable: 'onUpdate',
			emptyValue: '0',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['nilai_drm_material']),
		},
		{
			title: 'Nilai DRM Jasa',
			field: 'nilai_drm_jasa',
			emptyValue: '0',
			editable: 'onUpdate',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['nilai_drm_jasa']),
		},
		{
			title: 'NILAI DRM TOTAL',
			field: 'nilai_drm_total',
			emptyValue: '0',
			editable: 'never',
			cellStyle: {
				backgroundColor: '#63ccff',
				color: '#FFF',
			},
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['nilai_drm_total']),
		},
		{
			title: 'Nilai Aktual Material',
			field: 'nilai_aktual_material',
			emptyValue: '0',
			editable: 'onUpdate',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['nilai_aktual_material']),
		},
		{
			title: 'Nilai Aktual Jasa',
			field: 'nilai_aktual_jasa',
			emptyValue: '0',
			editable: 'onUpdate',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['nilai_aktual_jasa']),
		},
		{
			title: 'NILAI AKTUAL TOTAL',
			field: 'nilai_aktual_total',
			emptyValue: '0',
			editable: 'onUpdate',
			cellStyle: {
				backgroundColor: '#63ccff',
				color: '#FFF',
			},
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['nilai_aktual_total']),
		},
		{
			title: 'Harga Kesepakatan Awal Mitra ',
			field: 'harga_kesepakatan_awal_mitra',
			emptyValue: '0',
			editable: 'onUpdate',
			cellStyle: {
				backgroundColor: '#039be5',
				color: '#FFF',
			},
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['harga_kesepakatan_awal_mitra']),
		},
		{
			title: 'Harga Kesepakatan Akhir Mitra ',
			field: 'harga_kesepakatan_akhir_mitra',
			emptyValue: '0',
			editable: 'onUpdate',
			cellStyle: {
				backgroundColor: '#006db3',
				color: '#FFF',
			},
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['harga_kesepakatan_akhir_mitra']),
		},
	];

	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		const sekarang = new Date();
		const tahun = thn !== '' ? thn : sekarang.getFullYear();
		try {
			const responses = await getAPI(`/monitorings?year=${tahun}`);

			const firstTemp = responses.data.monitorings.map((item) => {
				const persentase = item.persentase * 100;
				return {
					...item,
					project_id: item.project.id,
					nama_project: item.project.nama,
					nama_mitra: item.mitra.id,
					persentase_view: persentase,
					nilai_drm_total: item.nilai_drm_material + item.nilai_drm_jasa,
					nilai_aktual_total:
						item.nilai_aktual_material + item.nilai_aktual_jasa,
					update_at: item.update_at,
					//kesepakatan	persentase	nilai_drm_material	nilai_drm_jasa	nilai_aktual_material	nilai_aktual_jasa	harga_kesepakatan_awal_mitra	harga_kesepakatan_akhir_mitra
				};
			});
			console.log(firstTemp);
			setMonitorings([...firstTemp]);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
		try {
			const responses = await axios.all([
				getAPI(`/projects?year=${tahun}`),
				getAPI('/mitras'),
				getAPI('/boqs'),
				getAPI('/rekons'),
				getAPI('/claim'),
			]);
			const [second, third, fourth, fifth, sixth] = responses.map(
				(x) => x.data
			);

			const secondTemp = second.projects.reduce((acc, cur, key) => {
				acc[cur.id] = cur.project_id;
				return acc;
			}, {});

			const thirdTemp = third.mitras.reduce((acc, cur, key) => {
				acc[cur.id] = cur.nama;
				return acc;
			}, {});

			setRekon(fifth.rekons);
			setBoq(fourth.boqs);
			setMitras({ ...thirdTemp });
			setProjects({ ...secondTemp });
			setUserDetail({ ...sixth.jwt });
		} catch (error) {
			console.error(error);
		}
	};

	if (
		userDetail.role_id === 1 ||
		userDetail.role_id === 2 ||
		userDetail.role_id === 3
	) {
		columns.push({
			title: 'Update At',
			field: 'update_at',
			editable: 'never',
		});
	}

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	const addItem = async (newData) => {
		const boqTemp = boq.map((item) => {
			const total_harga_material = item.harga_material * item.volume;
			const total_harga_jasa = item.harga_jasa * item.volume;
			return {
				...item,
				total_harga_material,
				total_harga_jasa,
			};
		});

		const rekonTemp = rekon.map((item) => {
			const total_material_rekon = item.boq.harga_material * item.volume;
			const total_jasa_rekon = item.boq.harga_jasa * item.volume;
			return {
				...item,
				total_material_rekon,
				total_jasa_rekon,
			};
		});

		const drm = boqTemp.filter(
			(x) => x.project.id === parseInt(newData.project_id)
		);
		const aktual = rekonTemp.filter(
			(x) => x.boq.project.id === parseInt(newData.project_id)
		);

		console.log(typeof drm[0].total_harga_material);

		let total_material_drm = 0;
		let total_jasa_drm = 0;
		let total_material_aktual = 0;
		let total_jasa_aktual = 0;

		for (let i = 0; i < drm.length; i++) {
			total_material_drm += drm[i].total_harga_material;
			total_jasa_drm += drm[i].total_harga_jasa;
		}

		for (let i = 0; i < aktual.length; i++) {
			total_material_aktual += aktual[i].total_material_rekon;
			total_jasa_aktual += aktual[i].total_jasa_rekon;
		}

		console.log({ total_material_drm });
		console.log({ total_jasa_drm });

		console.log({ total_material_aktual });
		console.log({ total_jasa_aktual });

		const persentaseConvert = parseFloat(newData.persentase_view) / 100;

		console.log({ persentaseConvert });

		const harga_awal = total_jasa_drm * persentaseConvert;
		const harga_akhir = total_jasa_aktual * persentaseConvert;

		console.log({ harga_awal });
		console.log({ harga_akhir });

		console.log(drm);

		console.log(newData.project_id);

		const body = {
			mitra_id: newData.nama_mitra,
			project_id: newData.project_id,
			kesepakatan: newData.kesepakatan,
			persentase: persentaseConvert,
			nilai_drm_material: total_material_drm,
			nilai_drm_jasa: total_jasa_drm,
			nilai_aktual_material: total_material_aktual,
			nilai_aktual_jasa: total_jasa_aktual,
			harga_kesepakatan_awal_mitra: harga_awal,
			harga_kesepakatan_akhir_mitra: harga_akhir,
		};

		console.log(body);

		try {
			await postAPI('/monitorings', body);
			fetchData();
		} catch (e) {
			alert('Error saat tambah monitoring');
			console.error(e);
		}
	};

	const updateItem = async (newData, oldData) => {
		const persentaseConvert = parseFloat(newData.persentase_view) / 100;
		const body = {
			mitra_id: newData.nama_mitra,
			project_id: newData.project_id,
			kesepakatan: newData.kesepakatan,
			persentase: persentaseConvert,
			nilai_drm_material: newData.nilai_drm_material,
			nilai_drm_jasa: newData.nilai_drm_jasa,
			nilai_aktual_material: newData.nilai_aktual_material,
			nilai_aktual_jasa: newData.nilai_aktual_jasa,
			harga_kesepakatan_awal_mitra: newData.harga_kesepakatan_awal_mitra,
			harga_kesepakatan_akhir_mitra: newData.harga_kesepakatan_akhir_mitra,
		};

		console.log(body);
		try {
			await putAPI(`/monitorings/${oldData.id}`, body);
			await fetchData();
		} catch (e) {
			alert('Error saat edit monitoring');
			console.error(e);
		}
	};

	const removeItem = async (rowData) => {
		try {
			await deleteAPI('/monitorings', rowData.id);
			await fetchData();
		} catch (error) {
			alert('Error saat menghapus monitoring');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Monitoring"
				action={
					<Select defaultValue={selectedYear} onChange={handleChange}>
						{arr?.map((x, id) => {
							const sekarang = new Date();
							const year = sekarang.getFullYear() - (arr.length - (id + 1));
							return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Tabel Monitoring"
						columns={columns}
						data={monitorings}
						editable={{
							onRowAdd: (newData) => addItem(newData),
							onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
							onRowDelete: (oldData) => removeItem(oldData),
						}}
						actions={[
							{
								icon: tableIcons.DeviceHub,
								tooltip: 'Termin',
								onClick: (event, rowData) =>
									history.push(`/dashboard/finance/termin`, {
										monitoringId: rowData.id,
									}),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
							exportButton: true,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default Monitoring;
