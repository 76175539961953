import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	Typography,
	makeStyles,
} from '@material-ui/core';
import React, { Fragment } from 'react';

const useStyle = makeStyles(() => ({
	separator: {
		width: '5px',
		backgroundColor: 'blue',
	},
}));

const FirstRow = ({ monitoring, separator }) => (
	<TableRow>
		<TableCell align="center">Nama Mitra Kerja</TableCell>
		<TableCell align="center">{monitoring?.mitra?.nama}</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nama Project</TableCell>
		<TableCell align="center">{monitoring?.project?.nama}</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nilai DRM Jasa</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(monitoring?.nilai_drm_jasa)}
		</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nilai Aktual Jasa</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(monitoring?.nilai_aktual_jasa)}
		</TableCell>
	</TableRow>
);

const SecondRow = ({ monitoring, separator }) => (
	<TableRow>
		<TableCell align="center">ID</TableCell>
		<TableCell align="center">{monitoring?.id}</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nama Customer</TableCell>
		<TableCell align="center">
			{monitoring?.project?.khs?.customer?.nama}
		</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nilai DRM Material</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(monitoring?.nilai_drm_material)}
		</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nilai Aktual Total</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(
				monitoring?.nilai_aktual_material + monitoring?.nilai_aktual_jasa
			)}
		</TableCell>
	</TableRow>
);

const ThirdRow = ({ monitoring, separator }) => (
	<TableRow>
		<TableCell align="center">ID Mitra</TableCell>
		<TableCell align="center">{monitoring?.mitra?.id}</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Kesepakatan</TableCell>
		<TableCell align="center">{monitoring?.kesepakatan}</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nilai DRM Total</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(monitoring?.nilai_drm_jasa + monitoring?.nilai_drm_material)}
		</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Harga Kesepakatan Awal Mitra</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(monitoring?.harga_kesepakatan_awal_mitra)}
		</TableCell>
	</TableRow>
);

const FourthRow = ({ monitoring, separator }) => (
	<TableRow>
		<TableCell align="center">ID Project</TableCell>
		<TableCell align="center">{monitoring?.project?.project_id}</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Persentase</TableCell>
		<TableCell align="center">{`${monitoring?.persentase * 100} %`}</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Nilai Aktual Material</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(monitoring?.nilai_aktual_material)}
		</TableCell>
		<TableCell className={separator}></TableCell>
		<TableCell align="center">Harga Kesepakatan Akhir Mitra</TableCell>
		<TableCell align="center">
			{Intl.NumberFormat('id', {
				style: 'currency',
				currency: 'idr',
			}).format(monitoring?.harga_kesepakatan_akhir_mitra)}
		</TableCell>
	</TableRow>
);

const Monitoring = ({ monitoring }) => {
	const classes = useStyle();
	return (
		<Fragment>
			<Typography
				gutterBottom
				variant="subtitle1"
				style={{ marginTop: '2rem' }}
			>
				Monitoring Project
			</Typography>
			<Table>
				<TableBody>
					<FirstRow monitoring={monitoring} separator={classes.separator} />
					<SecondRow monitoring={monitoring} separator={classes.separator} />
					<ThirdRow monitoring={monitoring} separator={classes.separator} />
					<FourthRow monitoring={monitoring} separator={classes.separator} />
				</TableBody>
			</Table>
		</Fragment>
	);
};

export default Monitoring;
