import { CircularProgress } from "@material-ui/core"
import React, { Fragment, useEffect, useState } from "react"

const FetchingLayer = (props) => {
  const { children, fetchData } = props
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    fetchData()
    setLoading(false)
  }, [])
  return loading ? (
    <CircularProgress size="5rem" />
  ) : (
    <Fragment>{children}</Fragment>
  )
}

export default FetchingLayer