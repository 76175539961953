import axios from 'axios';
import { Button, CardContent, CardHeader, TextField } from '@material-ui/core';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	CompareArrows,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { getAPI, putAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';
import { claim } from '../../../../helper/auth.js';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Compare: forwardRef((props, ref) => <CompareArrows {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const MaterialFullfillment = (props) => {
	const history = useHistory();
	const { requisitionId } = history.location.state;
	const [fullfillments, setFullfillments] = useState([]);
	const [requisitions, setRequisitions] = useState({});
	const [role, setRole] = useState('');
	const columns = [
		{
			title: 'Material Requisition ID',
			field: 'detail_material_requisition_id',
			lookup: requisitions,
			editable: 'never',
		},
		{
			title: 'Designator',
			field: 'detail_material_requisition.material.designator',
			editable: 'never',
		},
		{
			title: 'Uraian Pekerjaan',
			field: 'detail_material_requisition.material.nama',
			editable: 'never',
		},
		{
			title: 'Satuan',
			field: 'detail_material_requisition.material.satuan',
			editable: 'never',
		},
		{
			title: 'Volume Requisition',
			field: 'detail_material_requisition.volume',
			editable: 'never',
		},
		{
			title: 'Tanggal',
			field: 'tanggal',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Keterangan',
			field: 'keterangan',
		},
		{
			title: 'Volume Fulfillment',
			field: 'volume',
		},
	];

	const fetchData = async () => {
		try {
			const { data } = await claim();
			const responses = await axios.all([
				getAPI('/material_fulfillments'),
				getAPI('/detail_material_requisitions'),
			]);

			const [first, second] = responses.map((item) => item.data);
			const firstTemp = first.material_fulfillments.map((item) => {
				return {
					...item,
					detail_material_requisition_id: item.detail_material_requisition.id,
				};
			});
			const secondTemp = second.detail_material_requisitions.filter(
				(x) => x.material_requisition.id === requisitionId
			);
			const detailRequisitionsTemp = secondTemp.reduce((acc, cur) => {
				acc[cur.id] = cur.material_requisition.material_requisition_id;
				return acc;
			}, {});
			const tempRole = data.jwt.role_name.toLowerCase();

			setRole(tempRole);
			setFullfillments([
				...firstTemp.filter(
					(x) =>
						x.detail_material_requisition.material_requisition.id ===
						requisitionId
				),
			]);
			setRequisitions({ ...detailRequisitionsTemp });
		} catch (error) {
			console.error(error);
		}
	};

	const updateItem = async (newData, oldData) => {
		const body = {
			...newData,
			detail_material_requisition_id: newData.detail_material_requisition.id,
		};
		delete body.detail_material_requisition;
		try {
			await putAPI(`/material_fulfillments/${oldData.id}`, body);
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	const switchApproval = async (rowData) => {
		const approval = rowData.approval > 0 ? 0 : 1;
		const body = { ...rowData, approval };
		try {
			await putAPI(`/material_fulfillments/${rowData.id}`, body);
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Material Fulfillment"
				action={
					<Button
						variant="contained"
						color="primary"
						endIcon={<FontAwesomeIcon icon={faReply} />}
						onClick={() => history.goBack()}
					>
						Kembali
					</Button>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Material Fullfilment"
						columns={columns}
						data={fullfillments}
						editable={{
							onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
						}}
						actions={[
							{
								icon: tableIcons.Compare,
								tooltip: 'Ubah Approval',
								onClick: (event, rowData) => switchApproval(rowData),
								disabled: !['direktur', 'akun it'].includes(role),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: (rowData) => ({
								textAlign: 'center',
								backgroundColor: rowData.approval ? '#90ee90' : '#fff',
							}),
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default MaterialFullfillment;
