import React, { useEffect, useState } from 'react';
import axios from "axios"
import {
    Button,
    CardContent,
    CardHeader,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
    IconButton,

} from "@material-ui/core"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { faPaperPlane, faReply } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { getAPI } from "../../../../helper/api-consume.js"
import Dashboard from "../../../../layouts/dashboard.jsx"
import { useFormInput } from "../../../../helper/state.js"
import { useHistory, Link as RouterLink } from "react-router-dom"





const CreateUser = () => {
    const history = useHistory()
    const user = useFormInput({})
    const [roles, setRoles] = useState([])
    const [loading, setLoading] = useState(false)

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });


    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const fetchData = async () => {
        try {
            const { data } = await getAPI("/roles")
            console.log(data)
            setRoles([...data.roles])
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
        setLoading(false)
    }, [])

    const handleSubmit = async(e) => {
        e.preventDefault()
        try {
            const formData = new FormData()
            for (var key in user.value) {
                formData.append(key, user.value[key])
              }
            await axios({
                method: "post",
                url: `${process.env.REACT_APP_API_BASEURL}/users`,
                data: formData,
            })
            history.goBack()
        } catch (error) {
            console.error(error);
            
            
        }
    }



    return (
        <Dashboard>
            <CardHeader
                title="Tambah User"
                action={
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
                        component={RouterLink}
                        to="/dashboard/data-master/user"
                    >
                        kembali
                    </Button>
                }

            />
            <CardContent>
                {loading ? ("loading data..") : (
                    <Grid container justify="center">
                        <Grid item xs={8}>
                            <form
                                onSubmit={handleSubmit}
                                style={{ display: "flex", flexDirection: "column" }}
                                encType="multipart/form-data"
                            >
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    type="text"
                                    label="Username"
                                    name="name"
                                    onChange={user.handleChange}
                                />

                                <TextField
                                    fullWidth
                                    margin="normal"
                                    type="text"
                                    label="Email"
                                    name="email"
                                    onChange={user.handleChange}
                                />

                                <FormControl margin="normal">
                                    <InputLabel>User Role</InputLabel>
                                    <Select name="role_id" onChange={user.handleChange}>
                                        {roles.map((role, id) => (
                                            <MenuItem key={id} value={role.id}>
                                                {role.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl margin="normal">
                                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                    <Input
                                        id="standard-adornment-password"
                                        type={values.showPassword ? 'text' : 'password'}
                                        name="password"
                                        onChange={user.handleChange}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                <FormControl>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        endIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                                    >
                                        submit
                                    </Button>
                                </FormControl>

                            </form>
                        </Grid>


                    </Grid>
                )}
            </CardContent>


        </Dashboard>
    );
}

export default CreateUser;