import axios from 'axios';
import {
	CardContent,
	CardHeader,
	TextField,
	MenuItem,
	Select,
} from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	CompareArrows,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Search,
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';

import {
	deleteAPI,
	getAPI,
	postAPI,
	putAPI,
} from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';
import { claim } from '../../../../helper/auth.js';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Compare: forwardRef((props, ref) => <CompareArrows {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
};

const BarangMasuk = (props) => {
	const [barangMasuk, setBarangMasuk] = useState([]);
	const [materials, setMaterials] = useState({});
	const [gudangs, setGudangs] = useState({});
	const [suppliers, setSuppliers] = useState({});
	const [role, setRole] = useState('');
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const arr = new Array(5).fill(0);
	const columns = [
		{
			title: 'Designator',
			field: 'material_id',
			lookup: materials,
			editComponent: ({ onChange, value }) => (
				<Autocomplete
					id="combo-box-demo"
					options={[
						...Object.entries(materials).map((item) => ({
							id: item[0],
							title: item[1],
						})),
					]}
					getOptionLabel={(option) => option.title}
					value={value}
					onChange={(event, newValue) => onChange(newValue.id)}
					renderInput={(params) => <TextField {...params} />}
				/>
			),
		},
		{
			title: 'Uraian Pekerjaan',
			field: 'material.nama',
			editable: 'never',
		},
		{
			title: 'Satuan',
			field: 'material.satuan',
			editable: 'never',
		},
		{
			title: 'Volume',
			field: 'volume',
		},
		{
			title: 'Gudang',
			field: 'gudang_id',
			lookup: gudangs,
		},
		{
			title: 'Keterangan',
			field: 'keterangan',
		},
		{
			title: 'Tanggal',
			field: 'tanggal',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Supplier',
			field: 'supplier_id',
			lookup: suppliers,
		},
		{
			title: 'No Surat Jalan',
			field: 'nomor_surat_jalan',
		},
		{
			title: 'Alamat Pengirim',
			field: 'alamat_pengirim',
		},
	];
	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		const sekarang = new Date();
		const tahun = thn !== '' ? thn : sekarang.getFullYear();
		try {
			const { data } = await claim();
			const responses = await axios.all([
				getAPI(`/barang_masuks?year=${tahun}`),
				getAPI('/materials'),
				getAPI('/suppliers'),
				getAPI('/gudangs'),
			]);
			const [first, second, third, fourth] = responses.map((item) => item.data);

			let firstTemp = [];
			if (first.barang_masuks !== null) {
				firstTemp = first.barang_masuks.map((item) => {
					const supplier = third.suppliers.filter(
						(x) => x.nama === item.supplier.nama
					)[0];
					const gudang = fourth.gudangs.filter(
						(x) => x.nama === item.gudang.nama
					)[0];
					return {
						...item,
						material_id: item.material.id,
						supplier_id: supplier.id,
						gudang_id: gudang.id,
					};
				});
			}
			const secondTemp = second.materials.reduce((acc, cur) => {
				acc[cur.id] = cur.designator;
				return acc;
			}, {});
			const thirdTemp = third.suppliers.reduce((acc, cur) => {
				acc[cur.id] = cur.nama;
				return acc;
			}, {});
			const fourthTemp = fourth.gudangs.reduce((acc, cur) => {
				acc[cur.id] = cur.nama;
				return acc;
			}, {});
			const tempRole = data.jwt.role_name.toLowerCase();

			setRole(tempRole);
			setBarangMasuk([...firstTemp]);
			setMaterials({ ...secondTemp });
			setSuppliers({ ...thirdTemp });
			console.log(fourthTemp);
			delete fourthTemp[0];
			setGudangs({ ...fourthTemp });
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const addItem = async (newData) => {
		try {
			const { data } = await postAPI(`/barang_masuks`, newData);
			await fetchData();
		} catch (error) {
			alert('Error saat tambah data');
			console.error(error);
		}
	};

	const updateItem = async (newData, oldData) => {
		try {
			await putAPI(`/barang_masuks/${oldData.id}`, newData);
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	const removeItem = async (rowData) => {
		try {
			await deleteAPI('/barang_masuks', rowData.id);
			await fetchData();
		} catch (error) {
			alert('Error saat menghapus data');
			console.error(error);
		}
	};

	const switchApproval = async (rowData) => {
		const approval = rowData.approval > 0 ? 0 : 1;
		const body = { ...rowData, approval };
		try {
			await putAPI(`/barang_masuks/${rowData.id}`, body);
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Barang Masuk"
				action={
					<Select defaultValue={selectedYear} onChange={handleChange}>
						{arr?.map((x, id) => {
							const sekarang = new Date();
							const year = sekarang.getFullYear() - (arr.length - (id + 1));
							return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Barang Masuk"
						columns={columns}
						data={barangMasuk}
						editable={{
							onRowAdd: (newData) => addItem(newData),
							onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
							onRowDelete: (oldData) => removeItem(oldData),
						}}
						actions={[
							{
								icon: tableIcons.Compare,
								tooltip: 'Ubah Approval',
								onClick: (event, rowData) => switchApproval(rowData),
								disabled: !['direktur', 'akun it'].includes(role),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: (rowData) => ({
								textAlign: 'center',
								backgroundColor: rowData.approval ? '#90ee90' : '#fff',
							}),
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default BarangMasuk;
