import axios from 'axios'
import {
  Button,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { faReply } from '@fortawesome/free-solid-svg-icons'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Visibility,
} from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import MaterialTable from 'material-table'
import React, { forwardRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom'

import { getAPI, putAPI } from '../../../../helper/api-consume.js'
import Dashboard from '../../../../layouts/dashboard.jsx'
import FetchingLayer from '../../../../components/global/fetching-layer.jsx'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Eye: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const useStyles = makeStyles((theme) => ({
  bgCard: {
    background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
    width: '30%',
    borderRadius: 4,
    padding: '1rem',
    color: '#fff',
    marginBottom: 16,
  },
}))

const StatusProject = (props) => {
  const history = useHistory()
  const classes = useStyles()
  const { projectId } = history.location.state
  const [currentProject, setCurrentProject] = useState({})
  const [statusProjects, setStatusProjects] = useState([])
  const [boqs, setBoqs] = useState([])
  const [persentaseProgress, setPersentaseProgress] = useState('')
  const columns = [
    {
      title: 'Nomor',
      field: 'boq_id',
      lookup: boqs,
      editComponent: ({ onChange, value }) => (
        <Autocomplete
          id="combo-box-demo"
          options={[
            ...Object.entries(boqs).map((x) => ({
              id: x[0],
              title: x[1].toString(),
            })),
          ]}
          getOptionLabel={(option) => option.title}
          onChange={(event, newValue) => onChange(newValue.id)}
          renderInput={(params) => <TextField {...params} />}
        />
      ),
    },
    {
      title: 'Designator',
      field: 'boq.uraian_pekerjaan.material.designator',
      editable: 'never',
    },
    {
      title: 'Uraian Pekerjaan',
      field: 'boq.uraian_pekerjaan.material.nama',
      editable: 'never',
    },
    {
      title: 'Satuan',
      field: 'boq.uraian_pekerjaan.material.satuan',
      editable: 'never',
    },
    {
      title: 'Harga Material DRM',
      field: 'boq.harga_material',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'idr',
        }).format(data['boq']['harga_material']),
    },
    {
      title: 'Harga Jasa DRM',
      field: 'boq.harga_jasa',
      editable: 'never',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'idr',
        }).format(data['boq']['harga_jasa']),
    },
    {
      title: 'Volume BOQ DRM',
      field: 'boq.volume',
      editable: 'never',
    },
    {
      title: 'Volume Progress',
      field: 'volume',
    },
    {
      title: 'Keterangan',
      field: 'keterangan',
    },
  ]

  const fetchData = async () => {
    try {
      const responses = await axios.all([
        getAPI('/status_projects'),
        getAPI('/projects'),
        getAPI('/boqs'),
      ])
      const [first, second, third] = responses.map((x) => x.data)

      const curProject = second.projects.filter((x) => projectId === x.id)[0]
      setCurrentProject({ ...curProject })

      const firstTemp = first.status_projects.map((item) => {
        const { boq } = item
        return { ...item, boq_id: boq?.id }
      })
      const filteredProject = firstTemp.filter(
        (x) => x?.project?.project_id === curProject?.project_id,
      )
      const { volume_progress, volume_drm } = filteredProject.reduce(
        (acc, cur) => {
          acc['volume_progress'] += cur?.volume
          acc['volume_drm'] += cur?.boq?.volume
          return acc
        },
        {
          volume_progress: 0,
          volume_drm: 0,
        },
      )
      const persentase = (volume_progress / volume_drm) * 100
      setPersentaseProgress(
        persentase > 0 ? parseFloat(persentase).toFixed(2) : 0,
      )
      setStatusProjects([...filteredProject])

      const arrTempo = third.boqs.filter(
        (x) => x?.project?.id === curProject?.id,
      )
      const tempo = arrTempo.reduce((acc, cur) => {
        acc[cur?.id] = cur?.uraian_pekerjaan?.nomor_item
        return acc
      }, {})
      setBoqs(tempo)
    } catch (error) {
      console.error(error)
    }
  }

  const updateItem = async (newData, oldData) => {
    delete newData.boq
    try {
      await putAPI(`/status_projects/${oldData.id}`, newData)
      await fetchData()
    } catch (error) {
      alert(error)
      console.error()
    }
  }

  return (
    <Dashboard>
      <CardHeader
        title={
          <div className={classes.bgCard}>
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Persentase Progress {persentaseProgress}%
            </Typography>
          </div>
        }
        action={
          <Button
            variant="contained"
            color="primary"
            endIcon={<FontAwesomeIcon icon={faReply} />}
            onClick={() => history.goBack()}
          >
            Kembali
          </Button>
        }
      />
      <CardContent>
        <FetchingLayer fetchData={fetchData}>
          <MaterialTable
            icons={tableIcons}
            title={`Status Project ${currentProject?.nama}`}
            columns={columns}
            data={statusProjects}
            editable={{
              onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
            }}
            options={{
              headerStyle: {
                textAlign: 'center',
              },
              rowStyle: {
                textAlign: 'center',
              },
              actionsColumnIndex: -1,
            }}
          />
        </FetchingLayer>
      </CardContent>
    </Dashboard>
  )
}

export default StatusProject
