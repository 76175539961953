import React, { Fragment } from 'react';
import {
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';

const Termin = ({ termins }) => {
	return (
		<Fragment>
			<Typography
				gutterBottom
				variant="subtitle1"
				style={{ marginTop: '2rem' }}
			>
				Termin Project
			</Typography>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">ID Monitoring</TableCell>
						<TableCell align="center">Termin</TableCell>
						<TableCell align="center">Progres Pekerjaan</TableCell>
						<TableCell align="center">Persentase</TableCell>
						<TableCell align="center">Tanggal</TableCell>
						<TableCell align="center">Nilai Termin</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{termins?.map((termin, key) => (
						<TableRow key={key}>
							<TableCell align="center">{termin?.monitoring?.id}</TableCell>
							<TableCell align="center">
								{termin?.termin === 'akhir'
									? 'Pelunasan'
									: `Termin ke-${termin?.termin}`}
							</TableCell>
							<TableCell align="center">{termin?.progress_pekerjaan}</TableCell>
							<TableCell align="center">
								{`${termin?.persentase * 100} %`}
							</TableCell>
							<TableCell align="center">{termin?.tanggal}</TableCell>
							<TableCell align="center">
								{Intl.NumberFormat('id', {
									style: 'currency',
									currency: 'idr',
								}).format(termin?.nilai)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</Fragment>
	);
};

export default Termin;
