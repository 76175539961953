import axios from 'axios'
import {
  Button,
  CardContent,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { faFileExcel, faReply } from '@fortawesome/free-solid-svg-icons'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from '@material-ui/icons'
import MaterialTable from 'material-table'
import { Autocomplete } from '@material-ui/lab'
import React, { Fragment, forwardRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import xlsx from 'xlsx'

import {
  deleteAPI,
  getAPI,
  postAPI,
  putAPI,
} from '../../../../helper/api-consume.js'
import Dashboard from '../../../../layouts/dashboard.jsx'
import FetchingLayer from '../../../../components/global/fetching-layer.jsx'
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
}

const TUPCreate = () => {
  const history = useHistory()
  const { nomorKontrak } = useParams()
  const [noKontrak, setNoKontrak] = useState('')
  const [materials, setMaterials] = useState({})
  const [uraians, setUraians] = useState([])
  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const header = [
    {
      title: 'Id',
      field: 'id',
      editable: 'never',
    },
    {
      title: 'Nomor Item',
      field: 'nomor_item',
    },
    {
      title: 'Designator',
      field: 'material_id',
      lookup: materials,
      editComponent: ({ onChange, value }) =>
        Object.keys(materials).length > 0 ? (
          <Autocomplete
            id="combo-box-demo"
            options={[
              ...Object.entries(materials).map((item) => ({
                id: item[0],
                title: item[1],
              })),
            ]}
            getOptionLabel={(option) => option.title}
            onChange={(event, newValue) => onChange(newValue.id)}
            renderInput={(params) => <TextField {...params} />}
          />
        ) : (
          'data kosong'
        ),
    },
    {
      title: 'Uraian Pekerjaan',
      field: 'material.nama',
      editable: 'never',
    },
    {
      title: 'Satuan',
      field: 'material.satuan',
      editable: 'never',
    },
    {
      title: 'Harga Material',
      field: 'harga_material',
      type: 'numeric',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data['harga_material']),
    },
    {
      title: 'Harga Jasa',
      field: 'harga_jasa',
      type: 'numeric',
      render: (data) =>
        new Intl.NumberFormat('id-ID', {
          style: 'currency',
          currency: 'IDR',
        }).format(data['harga_jasa']),
    },
  ]

  const fetchData = async () => {
    try {
      const responses = await axios.all([
        getAPI(`/uraian_pekerjaans`),
        getAPI('/materials'),
      ])
      const [first, second] = responses.map((x) => x.data)
      const temp = second.materials.reduce((acc, cur, key) => {
        acc[cur.id] = cur.designator
        return acc
      }, {})
      const firstTemp = first.uraian_pekerjaans.filter(
        (x) => x.khs.id.toString() === nomorKontrak,
      )
      if (firstTemp.length > 0) {
        setNoKontrak(firstTemp[0]?.khs?.nomor_kontrak)
        setUraians([
          ...firstTemp.map((x) => ({ ...x, material_id: x.material.id })),
        ])
      }
      setMaterials({ ...temp })
    } catch (error) {
      console.error(error)
    }
  }

  const addData = async (newData, hotreload = true) => {
    const body = {
      ...newData,
      khs_id: nomorKontrak,
    }
    try {
      await postAPI('/uraian_pekerjaans', body)
      if (hotreload) {
        await fetchData()
      }
    } catch (e) {
      console.error(e)
    }
  }

  const updateData = async (newData, oldData) => {
    const body = {
      ...newData,
      khs_id: newData.khs.id,
    }
    delete body['khs']
    delete body['material']
    try {
      await putAPI(`/uraian_pekerjaans/${newData.id}`, body)
      await fetchData()
    } catch (e) {
      console.error(e)
    }
  }

  const handleFileUpload = ({ target }) => {
    setLoading(!loading)
    const file = target.files[0]
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = async (e) => {
      const bstr = e.target.result
      const wb = xlsx.read(bstr, { type: rABS ? 'binary' : 'array' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const temp = xlsx.utils.sheet_to_json(ws)
      await axios.all([temp.map((item) => addData(item, false))])
      await fetchData()
      setLoading(!loading)
      setIsOpen(!isOpen)
    }

    rABS ? reader.readAsBinaryString(file) : reader.readAsArrayBuffer(file)
  }

  const deleteData = async (oldData) => {
    try {
      await deleteAPI(`/uraian_pekerjaans`, oldData.id)
      await fetchData()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Dashboard>
      <CardHeader
        title={`Uraian Pekerjaan Kontrak No ${noKontrak}`}
        action={
          <Fragment>
            <Button
              margin="normal"
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faFileExcel} size="sm" />}
              onClick={() => setIsOpen(!isOpen)}
            >
              import
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
              component={RouterLink}
              to="/dashboard/data-master/khs"
            >
              kembali
            </Button>
          </Fragment>
        }
      />
      <CardContent>
        <Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)}>
          <DialogTitle>Upload File Uraian Pekerjaan</DialogTitle>
          <DialogContent>
            {loading ? (
              <CircularProgress />
            ) : (
              <TextField
                type="file"
                label="Upload File"
                name="file"
                InputLabelProps={{ shrink: true }}
                onChange={handleFileUpload}
              />
            )}
          </DialogContent>
        </Dialog>
        <FetchingLayer fetchData={fetchData}>
          <MaterialTable
            icons={tableIcons}
            title="Uraian Pekerjaan"
            columns={header}
            data={uraians}
            options={{
              headerStyle: {
                textAlign: 'center',
              },
              rowStyle: {
                textAlign: 'center',
              },
              actionsColumnIndex: -1,
            }}
            editable={{
              onRowAdd: (newData) => addData(newData),
              onRowUpdate: (newData, oldData) => updateData(newData, oldData),
              onRowDelete: (oldData) => deleteData(oldData),
            }}
          />
        </FetchingLayer>
      </CardContent>
    </Dashboard>
  )
}

export default TUPCreate
