import axios from "axios"

const baseurl = process.env.REACT_APP_API_BASEURL

const login = async ({ email, password }) => {
  const url = `${baseurl}/cookie/auth`
  const formData = new FormData()
  formData.append("email", email)
  formData.append("password", password)

  try {
    const response = await axios({
      method: "post",
      url,
      data: formData,
    })
    return response
  } catch (error) {
    throw error
  }
}

const logout = async () => {
  const url = `${baseurl}/cookie/auth/destroy`
  try {
    const response = await axios({
      method: "get",
      url,
    })
    return response
  } catch (error) {
    throw error
  }
}

const claim = async () => {
  try {
    const response = await axios({
      method: "get",
      url: `${baseurl}/claim`,
    })
    return response
  } catch (err) {
    throw err
  }
}

const renewToken = async () => {
  try {
    await axios({
      method: "get",
      url: `${baseurl}/cookie/auth/renew`,
    })
  } catch (error) {
    throw error
  }
}

export { login, logout, renewToken, claim }
