import {
	Button,
	CardContent,
	CardHeader,
	Dialog,
	DialogActions,
	MenuItem,
	Select,
	DialogTitle,
} from '@material-ui/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	DeleteOutline,
	DeviceHub,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	PieChart,
	Remove,
	SaveAlt,
	Search,
	ViewColumn,
	Visibility,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import { deleteAPI, getAPI } from '../../../../helper/api-consume.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	DeviceHub: forwardRef((props, ref) => <DeviceHub {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	PieChart: forwardRef((props, ref) => <PieChart {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
	Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
};

const Project = (props) => {
	const history = useHistory();
	const [isOpen, setIsOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(-1);
	const [project, setProject] = useState([]);
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const arr = new Array(5).fill(0);

	const columns = [
		{
			title: 'ID Project',
			field: 'project_id',
		},
		{ title: 'Customer', field: 'khs.customer.nama' },
		{
			title: 'KHS',
			field: 'khs.nomor_kontrak',
		},
		{
			title: 'Regional',
			field: 'detail_lokasi',
			render: (data) =>
				data['detail_lokasi'] === '0' ? 'Nasional' : data['detail_lokasi'],
		},
		{
			title: 'Nama Project',
			field: 'nama',
		},
		{
			title: 'Witel',
			field: 'witel',
		},
		{
			title: 'No Surat Pesanan',
			field: 'nomor_surat_pesanan',
		},
		{
			title: 'Tanggal Surat Pesanan',
			field: 'tanggal_surat_pesanan',
		},
		{
			title: 'No PO',
			field: 'nomor_po',
		},
		{
			title: 'Tanggal PO',
			field: 'tanggal_po',
		},
	];

	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		const sekarang = new Date();
		const tahun = thn !== '' ? thn : sekarang.getFullYear();
		try {
			const { data } = await getAPI(`/projects?year=${tahun}`);
			setProject([...data.projects]);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	const handleRemove = (id) => {
		setIsOpen(!isOpen);
		setSelectedId(id);
	};

	const removeProject = async (rowData) => {
		try {
			await deleteAPI('/projects', rowData.id);
			await fetchData();
			handleRemove(-1);
		} catch (error) {
			alert('Error saat menghapus project');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Project"
				action={
					<>
						<Select
							defaultValue={selectedYear}
							onChange={handleChange}
							style={{ marginRight: '16px' }}
						>
							{arr?.map((x, id) => {
								const sekarang = new Date();
								const year = sekarang.getFullYear() - (arr.length - (id + 1));
								return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
							})}
						</Select>
						<Button
							variant="contained"
							color="primary"
							endIcon={<FontAwesomeIcon icon={faPlus} size="sm" />}
							onClick={() => history.push('create')}
						>
							tambah
						</Button>
					</>
				}
			/>
			<CardContent>
				<Dialog open={isOpen} onClose={() => setIsOpen(!isOpen)}>
					<DialogTitle>Yakin Ingin Menghapus Data Ini ?</DialogTitle>
					<DialogActions>
						<Button color="primary" onClick={() => setIsOpen(!isOpen)}>
							Tidak
						</Button>
						<Button color="primary" onClick={() => removeProject(selectedId)}>
							Ya
						</Button>
					</DialogActions>
				</Dialog>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Project"
						columns={columns}
						data={project}
						actions={[
							{
								icon: tableIcons.DeviceHub,
								tooltip: 'Daftar BOQ DRM',
								onClick: (event, rowData) =>
									history.push(`/dashboard/project-system/boq`, {
										khsId: rowData.khs.id,
										projectId: rowData.id,
									}),
							},
							{
								icon: tableIcons.PieChart,
								tooltip: 'Daftar BOQ Rekon',
								onClick: (event, rowData) =>
									history.push(`/dashboard/project-system/rekon`, {
										projectId: rowData.id,
									}),
							},
							{
								icon: tableIcons.Visibility,
								tooltip: 'Detail Project',
								onClick: (event, rowData) =>
									history.push(
										`/dashboard/project-system/project/${rowData.id}`
									),
							},
							{
								icon: tableIcons.Edit,
								tooltip: 'Edit Project',
								onClick: (event, rowData) =>
									history.push(
										`/dashboard/project-system/project/${rowData.id}/edit`
									),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default Project;
