import axios from 'axios';
import {
	CardContent,
	CardHeader,
	TextField,
	MenuItem,
	Select,
} from '@material-ui/core';
import {
	AddBox,
	ArrowDownward,
	Check,
	ChevronLeft,
	ChevronRight,
	Clear,
	CompareArrows,
	DeleteOutline,
	Edit,
	FilterList,
	FirstPage,
	LastPage,
	Remove,
	Search,
	ViewColumn,
} from '@material-ui/icons';
import MaterialTable from 'material-table';
import React, { forwardRef, useState, useEffect } from 'react';

import { getAPI, putAPI } from '../../../../helper/api-consume.js';
import { claim } from '../../../../helper/auth.js';
import Dashboard from '../../../../layouts/dashboard.jsx';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Compare: forwardRef((props, ref) => <CompareArrows {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const AdminPenagihan = (props) => {
	const [adminPenagihan, setAdminPenagihan] = useState([]);
	const [projects, setProjects] = useState({});
	const [role, setRole] = useState('');
	const [thisYear, setThisYear] = useState(new Date());
	const [selectedYear, setSelectedYear] = useState(thisYear.getFullYear());
	const [loading, setLoading] = useState(true);
	const arr = new Array(5).fill(0);
	const columns = [
		{
			title: 'Project ID',
			field: 'project_id',
			lookup: projects,
		},
		{
			title: 'Nama Project',
			field: 'project.nama',
			editable: 'never',
		},
		{
			title: 'Tanggal Progres 100%',
			field: 'tanggal_progress',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_progress"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Nomor Surat Pesanan',
			field: 'project.nomor_surat_pesanan',
			editable: 'never',
		},
		{
			title: 'Tanggal Surat Pesanan',
			field: 'project.tanggal_surat_pesanan',
			editable: 'never',
		},
		{
			title: 'Nomor PO',
			field: 'project.nomor_po',
			editable: 'never',
		},
		{
			title: 'Tanggal PO',
			field: 'project.tanggal_po',
			editable: 'never',
		},
		{
			title: 'BA Rekon',
			field: 'ba_rekon',
		},
		{
			title: 'Tanggal BA Rekon',
			field: 'tanggal_ba_rekon',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_ba_rekon"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'BAUT',
			field: 'baut',
		},
		{
			title: 'Tanggal BAUT',
			field: 'tanggal_baut',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_baut"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'BAST',
			field: 'bast',
		},
		{
			title: 'Tanggal BAST',
			field: 'tanggal_bast',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_bast"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Invoice',
			field: 'invoice',
		},
		{
			title: 'Tanggal Invoice',
			field: 'tanggal_invoice',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_invoice"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Jaminan Pelaksanaan',
			field: 'jaminan_pelaksanaan',
		},
		{
			title: 'Tanggal Jaminan Pelaksanaan',
			field: 'tanggal_jaminan_pelaksanaan',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_jaminan_pelaksanaan"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Jaminan Pemeliharaan',
			field: 'jaminan_pemeliharaan',
		},
		{
			title: 'Tanggal Jaminan Pemeliharaan',
			field: 'tanggal_jaminan_pemeliharaan',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_jaminan_pemeliharaan"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Cash and Bank Jasa',
			field: 'cash_and_bank',
			render: (data) =>
				new Intl.NumberFormat('id-ID', {
					style: 'currency',
					currency: 'IDR',
				}).format(data['cash_and_bank']),
		},
		{
			title: 'Tanggal Cash and Bank Jasa',
			field: 'tanggal_cb',
			editComponent: ({ value, onChange }) => (
				<TextField
					type="date"
					name="tanggal_cash_and_bank"
					value={value}
					onChange={({ target }) => onChange(target.value)}
				/>
			),
		},
		{
			title: 'Posisi Dokumen',
			field: 'posisi_dokumen',
		},
		{
			title: 'Kendala',
			field: 'kendala',
		},
	];

	const handleChange = ({ target }) => {
		setSelectedYear(target.value);
	};

	const fetchData = async (thn = '') => {
		const sekarang = new Date();
		const tahun = thn !== '' ? thn : sekarang.getFullYear();
		try {
			const { data } = await claim();
			const responses = await axios.all([
				getAPI(`/adm_penagihans?year=${tahun}`),
				getAPI(`/projects?year=${tahun}`),
			]);

			const tempRole = data.jwt.role_name.toLowerCase();

			const [first, second] = responses.map((x) => x.data);
			const secondTemp = second.projects.reduce((acc, cur) => {
				acc[cur.id] = cur.project_id;
				return acc;
			}, {});

			const firstTemp = first.adm_penagihans.map((item) => ({
				...item,
				project_id: item.project.id,
			}));

			setRole(tempRole);
			setProjects({ ...secondTemp });
			setAdminPenagihan([...firstTemp]);
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (loading) {
			fetchData(selectedYear);
		} else if (selectedYear !== '') {
			fetchData(selectedYear);
		}
	}, [selectedYear]);

	const updateItem = async (newData, oldData) => {
		try {
			await putAPI(`/adm_penagihans/${oldData.id}`, newData);
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	const switchApproval = async (rowData) => {
		const approval = rowData.approval > 0 ? 0 : 1;
		const body = { ...rowData, approval };
		try {
			await putAPI(`/adm_penagihans/${rowData.id}`, body);
			await fetchData();
		} catch (error) {
			alert('Error saat update data');
			console.error(error);
		}
	};

	return (
		<Dashboard>
			<CardHeader
				title="Admin Penagihan"
				action={
					<Select defaultValue={selectedYear} onChange={handleChange}>
						{arr?.map((x, id) => {
							const sekarang = new Date();
							const year = sekarang.getFullYear() - (arr.length - (id + 1));
							return <MenuItem value={year}>{`Tahun ${year}`}</MenuItem>;
						})}
					</Select>
				}
			/>
			<CardContent>
				<FetchingLayer fetchData={fetchData}>
					<MaterialTable
						icons={tableIcons}
						title="Admin Penagihan"
						columns={columns}
						data={adminPenagihan}
						editable={{
							isEditHidden: (rowData) =>
								rowData.approval && role === 'admin finance',
							onRowUpdate: (newData, oldData) => updateItem(newData, oldData),
						}}
						actions={[
							{
								icon: tableIcons.Compare,
								tooltip: 'Ubah Approval',
								onClick: (event, rowData) => switchApproval(rowData),
								disabled: !['akun it', 'admin finance pusat'].includes(role),
							},
						]}
						options={{
							headerStyle: {
								textAlign: 'center',
							},
							rowStyle: (rowData) => ({
								backgroundColor: rowData.approval ? '#90ee90' : '#fff',
							}),
							actionsColumnIndex: -1,
						}}
					/>
				</FetchingLayer>
			</CardContent>
		</Dashboard>
	);
};

export default AdminPenagihan;
