import React, { forwardRef, useState } from 'react';
import Dashboard from '../../../../layouts/dashboard';
import {
	Button,
	CardContent,
	Card,
	Typography,
	TextField,
} from '@material-ui/core';
import FetchingLayer from '../../../../components/global/fetching-layer.jsx';
import { Link as RouterLink, useParams } from 'react-router-dom';

// material component
import MaterialTable from 'material-table';

//icon import material ui
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core/styles';

import {
	deleteAPI,
	getAPI,
	postAPI,
	putAPI,
} from '../../../../helper/api-consume.js';
import { red } from '@material-ui/core/colors';

const tableIcons = {
	Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
	Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
	Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
	DetailPanel: forwardRef((props, ref) => (
		<ChevronRight {...props} ref={ref} />
	)),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => (
		<ChevronLeft {...props} ref={ref} />
	)),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
	avatar: {
		backgroundColor: red[500],
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	bgCard: {
		display: 'flex',
		maxWidth: '30%',
		background: 'linear-gradient(45deg, #80D0C7 30%, #0093E9  90%)',
		borderRadius: 4,
		padding: 10,
		color: '#fff',
		marginBottom: 16,
	},
	headerCOntainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	btn: {
		height: 40,
	},
}));

const Operasional = (props) => {
	const classes = useStyles();
	const [operasionals, setOperasionals] = useState([]);
	const { idpermintaan } = useParams();
	const [mingguKe, setMingguKe] = useState();
	const [permintaaName, setPermintaanName] = useState('');
	const tableTile = `Operasional ${permintaaName} Minggu ${mingguKe}`;
	let totalKeperluan = 0;
	console.log(operasionals);
	if (operasionals) {
		for (let i = 0; i < operasionals.length; i++) {
			totalKeperluan += parseInt(operasionals[i].nilai_keperluan);
		}
	}
	console.log(totalKeperluan);

	const formatTotal = new Intl.NumberFormat('id-ID', {
		style: 'currency',
		currency: 'IDR',
	}).format(totalKeperluan);

	console.log(formatTotal);

	const title = `Total Operasional : ${formatTotal}`;

	const [state, setState] = React.useState({
		columns: [
			{ title: 'ID', field: 'id', editable: 'never', export: false },
			{
				title: 'Tanggal',
				field: 'tanggal',
				editComponent: ({ value, onChange }) => (
					<TextField
						type="date"
						name="tanggal"
						value={value}
						onChange={({ target }) => onChange(target.value)}
					/>
				),
			},
			{ title: 'Keperluan', field: 'keperluan' },
			{
				title: 'Nilai Keperluan',
				field: 'nilai_keperluan',
				type: 'numeric',
				render: (data) =>
					new Intl.NumberFormat('id-ID', {
						style: 'currency',
						currency: 'IDR',
					}).format(data['nilai_keperluan']),
			},
			{ title: 'Keterangan', field: 'keterangan' },
		],
		data: [],
	});

	const fetchData = async () => {
		try {
			const { data } = await getAPI(
				`/filters/operasional/permintaan/${idpermintaan}`
			);
			setOperasionals(data.operasionals);
		} catch (error) {
			console.error(error);
		}
		try {
			const { data } = await getAPI(`/permintaans/${idpermintaan}`);
			setMingguKe(data.permintaans[0].ke);
			setPermintaanName(data.permintaans[0].user.name);
		} catch (error) {
			console.error(error);
		}
	};

	const addData = async (newData) => {
		const body = {
			tanggal: newData.tanggal,
			keperluan: newData.keperluan,
			keterangan: newData.keterangan,
			nilai_keperluan: newData.nilai_keperluan,
			permintaan_id: idpermintaan,
		};
		try {
			await postAPI('/operasionals', body);
			fetchData();
		} catch (e) {
			console.error(e);
		}
	};

	const updateData = async (newData, oldData) => {
		const body = {
			tanggal: newData.tanggal,
			keperluan: newData.keperluan,
			keterangan: newData.keterangan,
			nilai_keperluan: newData.nilai_keperluan,
			permintaan_id: idpermintaan,
		};
		try {
			await putAPI(`/operasionals/${newData.id}`, body);
			await fetchData();
		} catch (e) {
			console.error(e);
		}
	};

	const deleteData = async (oldData) => {
		try {
			console.log(oldData.id);
			const { data } = await deleteAPI(`/operasionals`, oldData.id);
			fetchData();
			console.log(data);
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<Dashboard>
			<Card>
				<CardContent>
					<div className={classes.headerCOntainer}>
						<div className={classes.bgCard}>
							<Typography variant="h6" style={{ flexGrow: 1 }}>
								{title}
							</Typography>
						</div>
						<Button
							className={classes.btn}
							variant="contained"
							color="primary"
							endIcon={<FontAwesomeIcon icon={faReply} size="sm" />}
							component={RouterLink}
							to="/dashboard/finance/permintaan"
						>
							kembali
						</Button>
					</div>
					<FetchingLayer fetchData={fetchData}>
						<MaterialTable
							icons={tableIcons}
							title={tableTile}
							columns={state.columns}
							data={operasionals}
							options={{
								headerStyle: {
									textAlign: 'center',
								},
								rowStyle: {
									textAlign: 'center',
								},
								actionsColumnIndex: -1,
								exportButton: true,
							}}
							editable={{
								onRowAdd: (newData) => addData(newData),
								onRowUpdate: (newData, oldData) => updateData(newData, oldData),
								onRowDelete: (oldData) => deleteData(oldData),
							}}
						/>
					</FetchingLayer>
				</CardContent>
			</Card>
		</Dashboard>
	);
};

export default Operasional;
